import {
  TaskManagementNoteTypeCreateDocument,
  TaskManagementNoteTypeDeleteDocument,
  TaskManagementNoteTypeGetByIdDocument,
  TaskManagementNoteTypeListDocument,
  TaskManagementNoteTypeListPaginatedDocument,
  TaskManagementNoteTypeUpdateDocument,
} from "api/generated";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import { ResourceEnum, ScopeEnum } from "config";

import {
  ICreateNoteTypeVars,
  IGetNoteTypeByIdReturnType,
  IGetNotesListArgs,
  INoteTypeModel,
  INotesListReturnType,
  IUpdateNoteTypeVars,
} from "../types";

export async function getTaskManagementNoteTypesList(
  variables: IGetNotesListArgs
): Promise<INotesListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskManagementNoteTypeListPaginatedDocument,
      variables,
    });
    return res?.data?.taskManagementNoteTypeListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createTaskManagementNoteType(
  variables: ICreateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskManagementNoteTypeCreateDocument,
      variables,
    });
    return res?.data?.taskManagementNoteTypeCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateTaskManagementNoteType(
  variables: IUpdateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskManagementNoteTypeUpdateDocument,
      variables,
    });
    return res?.data?.taskManagementNoteTypeUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteTaskManagementNoteType(
  noteTypeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskManagementNoteTypeDeleteDocument,
      variables: {
        noteTypeId,
      },
    });
    return Boolean(res?.data?.taskManagementNoteTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getTaskManagementNoteType(
  noteTypeId: number
): Promise<IGetNoteTypeByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskManagementNoteTypeGetByIdDocument,
      variables: {
        noteTypeId,
      },
    });
    return res?.data?.taskManagementNoteTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function getTaskManagementNoteTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<INoteTypeModel, "noteTypeId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskManagementNoteTypeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.taskManagementNoteTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

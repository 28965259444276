import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrQuantPerformanceCreateValuationDocument,
  HrQuantPerformanceDeleteValuationDocument,
  HrQuantPerformanceListDocument,
  HrQuantPerformanceUpdateValuationDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IHrQuantPerformanceModel,
  IHrQuantPerformanceCreateValuationVars,
  IHrQuantPerformanceUpdateValuationVars,
} from "./types";

export async function getHrQuantPerformanceList(
  productFundId?: number[]
): Promise<Optional<IHrQuantPerformanceModel[]>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrQuantPerformanceListDocument,
      variables: {
        productFundId,
      },
    });
    return res?.data?.hrQuantPerformanceList;
  } catch (error) {
    throw error;
  }
}

export async function createValuationHrQuantPerformance(
  input: IHrQuantPerformanceCreateValuationVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrQuantPerformanceCreateValuationDocument,
      variables: input,
    });
    return res?.data?.hrQuantPerformanceCreateValuation ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateValuationHrQuantPerformance(
  input: IHrQuantPerformanceUpdateValuationVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrQuantPerformanceUpdateValuationDocument,
      variables: input,
    });
    return res?.data?.hrQuantPerformanceUpdateValuation ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteValuationHrQuantPerformance(
  id: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrQuantPerformanceDeleteValuationDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrQuantPerformanceDeleteValuation ?? false;
  } catch (error) {
    throw error;
  }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getPwmProfileTradingPaginated,
  IPwmProfileTradingPaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

import {
  IWesterosPwmTradingModalData,
  WesterosPwmTradingModalMode,
} from "./types";

const initialState = {
  list: null as Nullable<IPwmProfileTradingPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: WesterosPwmTradingModalMode.VIEW,
  } as IWesterosPwmTradingModalData,
};

export const fetchWesterosPwmTrading = createAsyncThunk(
  "westeros-pwm-trading/fetchWesterosPwmTrading",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getPwmProfileTradingPaginated(filters);
    return response;
  }
);

const westerosPwmTradingSlice = createSlice({
  name: "westeros-pwm-trading",
  initialState,
  reducers: {
    setWesterosPwmTradingModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeWesterosPwmTradingModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosPwmTrading.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        list: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchWesterosPwmTrading.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setWesterosPwmTradingModalData, closeWesterosPwmTradingModal } =
  westerosPwmTradingSlice.actions;
export default westerosPwmTradingSlice.reducer;

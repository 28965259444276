import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  PwmProfileExchangeGetByIdDocument,
  PwmProfileExchangeListPaginatedDocument,
  PwmProfileExchangeCreateDocument,
  PwmProfileExchangeUpdateDocument,
  PwmProfileExchangeDeleteDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IPwmProfileExchangeCreateVars,
  IPwmProfileExchangeModel,
  IPwmProfileExchangePaginatedResponse,
  IPwmProfileExchangePaginatedVars,
  IPwmProfileExchangeUpdateVars,
} from "./types";

export async function getPwmProfileExchange(
  id: number
): Promise<Optional<IPwmProfileExchangeModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileExchangeGetByIdDocument,
      variables: {
        id,
      },
    });
    return res?.data?.pwmProfileExchangeGetById;
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfileExchangePaginated(
  variables: IPwmProfileExchangePaginatedVars
): Promise<Optional<IPwmProfileExchangePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileExchangeListPaginatedDocument,
      variables,
    });
    return res?.data?.pwmProfileExchangeListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createPwmProfileExchange(
  variables: IPwmProfileExchangeCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileExchangeCreateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileExchangeCreate);
  } catch (error) {
    throw error;
  }
}

export async function updatePwmProfileExchange(
  variables: IPwmProfileExchangeUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileExchangeUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileExchangeUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deletePwmProfileExchange(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileExchangeDeleteDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.pwmProfileExchangeDelete);
  } catch (error) {
    throw error;
  }
}

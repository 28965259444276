export abstract class AbstractEnum {
  protected static choices: { [key: number]: any } = {};

  public static getValueName(value: any) {
    return this.choices[value] || undefined;
  }

  public static getReadableValues() {
    return this.choices;
  }

  public static getValues(): number[] {
    return Object.keys(this.choices).map((key) => Number(key));
  }

  public static getSelectiveOptions() {
    return Object.keys(this.choices).map((key) => ({
      value: Number(key).toString(),
      label: this.choices[key as any],
    }));
  }
}

export enum STORAGE_CONSTANTS {
  ACCESS_TOKEN = "token",
  REFRESH_TOKEN = "token_refresh",
  ID_TOKEN = "idToken",
  COUNTRIES = "countries",
  COUNTRIESPHONECODES = "countriesPhoneCodes",
  SIDEBAR_EXPANDED = "sidebarExpanded",
  DRAFT = "draft",
  USER_DRAFT = "userDraft",
  USER_DRAFT_PERMISSIONS = "userPermissionsDraft",
  CONVERSION_DRAFT = "conversionDraft",
  DOCUMENTS_DRAFT = "documentsDraft",
  DOCUMENTS_DOCUMENT_DRAFT = "documentsDocumentDraft",
  WESTEROS_DOCUMENTS_DRAFT = "westerosDocumentsDraft",
  WESTEROS_DOCUMENTS_DOCUMENT_DRAFT = "westerosDocumentsDocumentDraft",
  INVESTMENT_NOTE_TYPE_DRAFT = "investmentNoteTypeDraft",
  WESTEROS_NOTE_TYPE_DRAFT = "westerosNoteTypeDraft",
  INVESTMENT_PRODUCT_FUNDS_DRAFT = "investmentProductFundDraft",
  INVESTMENT_SOURCE_DAFT = "sourceDraft",
  ADVISORY_PROJECT_DRAFT = "advisoryProjectTagDraft",
  INVESTMENT_TAG_DRAFT = "investmentTagDraft",
  WESTEROS_TAG_DRAFT = "westerosTagDraft",
  WESTEROS_SERVICE_DRAFT = "westerosServiceDraft",
  CAPITAL_MOVEMENT_DIVIDEND_DRAFT = "capitalMovementDividendDraft",
  CAPITAL_MOVEMENT_DRAFT = "capitalMovementDraft",
  CAPITAL_MOVEMENT_DOCS_DRAFT = "capitalMovementDocsDraft",
  CAPITAL_MOVEMENT_CHILDREN_DATAS_DRAFT = "capitalMovementChildrenDataDraft",
  CAPITAL_MOVEMENT_INPUTS_DATA_DRAFT = "capitalMovementInputsDataDraft",
  CAPITAL_MOVEMENT_DYNAMIC_FORM_DRAFT = "capitalMovementDynamicFormDraft",
  WESTEROS_CLIENTS_DRAFT = "westerosClientsDraft",
  WESTEROS_CLIENTS_TAGS_DRAFT = "westerosClientsTagsDraft",
  WESTEROS_CLIENTS_NOTES_DRAFT = "westerosClientsNotesDraft",
  WESTEROS_CLIENTS_DOC_LIST_DOCS_DRAFT = "westerosClientsDocListDocsDraft",
  WESTEROS_CLIENTS_ADDITIONAL_DOCS_DRAFT = "westerosClientsAdditionalDocsDraft",
  WESTEROS_CLIENTS_UBOS_DRAFT = "westerosClientsUbosDraft",
  WESTEROS_CLIENT_LIQUIDITIES_DRAFT = "westerosClientLiquidityDraft",
  WESTEROS_CLIENT_ADVISORY_TOKENS_DRAFT = "westerosClientAdvisoryTokensDraft",
  ADVISORY_BUYER_FORM_DRAFT = "westerosAdvisoryBuyerFormDraft",
  ADVISORY_BUYER_WALLET_FORM_DRAFT = "westerosAdvisoryBuyerWalletFormDraft",
  ADVISORY_PURCHASE_FORM_DRAFT = "westerosAdvisoryPurchaseFormDraft",
  ADVISORY_TRANCHE_FORM_DRAFT = "westerosAdvisoryTrancheFormDraft",
  ADVISORY_EXIT_PLAN_FORM_DRAFT = "westerosAdvisoryExitPlanFormDraft",
  ADVISORY_EXIT_PLAN_EXIT_FORM_DRAFT = "westerosAdvisoryExitPlanExitFormDraft",
  ADVISORY_DISTRIBUTION_FORM_DRAFT = "westerosAdvisoryDistributionFormDraft",
  LINK_AGENT_FORM_DRAFT = "linkAgentFormDraft",
  LINK_AGENT_DOCUMENTS_DRAFT = "linkAgentDocumentsDraft",
  LINK_AGENT_NOTES_DRAFT = "linkAgentNotesDraft",
  WEEKLY_UPDATES_DOCS_DRAFT = "weeklyUpdatesDocsDraft",
  WEEKLY_UPDATES_FORM_DRAFT = "weeklyUpdatesFormDraft",
  ANNOUNCEMENTS_DOCS_DRAFT = "announcementsDocsDraft",
  ANNOUNCEMENTS_FORM_DRAFT = "announcementsFormDraft",
  UPCOMING_EVENTS_DOCS_DRAFT = "upcomingEventsDocsDraft",
  UPCOMING_EVENTS_FORM_DRAFT = "upcomingEventsFormDraft",
  STATEMENT_FORM_DRAFT = "statementFormDraft",
  STATEMENT_DOCUMENT_DRAFT = "statementDocumentDraft",
  REPORTS_FORM_DRAFT = "reportFormDraft",
  REPORTS_DOC_DRAFT = "reportDocumentDraft",
  LINK_AGENT_PAYOUT_DRAFT = "linkAgentPayoutDraft",
  LINK_AGENT_PAYOUT_DOCUMENT_DRAFT = "linkAgentPayoutDocsDraft",
  VENTURE_NOTE_TYPE_FORM_DRAFT = "ventureNoteTypeFormDraft",
  VENTURE_TAG_FORM_DRAFT = "ventureTagFormDraft",
  VENTURE_DOCUMENTS_DOCUMENT_DRAFT = "ventureDocumentsDocumentDraft",
  VENTURE_DOCUMENTS_DRAFT = "ventureDocumentsDraft",
  VENTURE_SECTOR_FORM_DRAFT = "ventureSectorFormDraft",
  RESOURCE_FORM_DRAFT = "resourceFormDraft",
  RESOURCE_DOCUMENTS_DRAFT = "resourceDocumentsDraft",
  EMAIL_TEMPLATE_FORM_DRAFT = "emailTemplateFormDraft",
  ORIGINATION_SOURCE_FORM_DRAFT = "originationSourceFormDraft",
  VENTURE_FUND_FORM_DRAFT = "ventureFundFormDraft",
  VENTURE_BLOCKCHAIN_FORM_DRAFT = "ventureBlockchainFormDraft",
  VENTURE_PROJECT_TYPE_FORM_DRAFT = "ventureProjectTypeFormDraft",
  VENTURE_INVESTMENT_METHOD_FORM_DRAFT = "ventureInvestmentMethodFormDraft",
  VENTURE_PROJECT_PROFILE_FORM_DRAFT = "ventureProjectProfileFormDraft",
  VENTURE_PROJECT_PROFILE_TAGS_DRAFT = "ventureProjectProfileTagsDraft",
  VENTURE_PROJECT_PROFILE_NOTES_DRAFT = "ventureProjectProfileNotesDraft",
  VENTURE_PROJECT_PROFILE_ADDITIONAL_DOCS_DRAFT = "ventureProjectProfileAdditionalDocsDraft",
  VENTURE_PROJECT_PROFILE_DOC_LIST_DOCS_DRAFT = "ventureProjectProfileDocListsDocsDraft",
  VENTURE_PROJECT_PROFILE_FOUNDER_CONTACT_DRAFT = "ventureProjectProfileFounderContactsDraft",
  VENTURE_PROJECT_PROFILE_DUE_DILIGENCE_DOCUMENTS_DRAFT = "ventureProjectProfileDueDiligenceDocumentsDraft",
  VENTURE_CONTACT_TYPE_FORM_DRAFT = "ventureContactTypeFormDraft",
  VENTURE_VALUATION_CONTROL_FORM_DRAFT = "ventureValuationControlFormDraft",
  VENTURE_CONTACT_FORM_DRAFT = "ventureContactFormDraft",
  ASSET_ATTRIBUTION_INVESTMETNS_DRAFT = "assetAttributionInvestmentsDraft",
  ASSET_ATTRIBUTION_TRANSFER_SOURCES_DRAFT = "assetAttributionTransferSourcesDraft",
  ASSET_ATTRIBUTION_EXITS_DRAFT = "assetAttributionExitsDraft",
  SYSTEM_TASK_MANAGEMENT_TASK_TYPE_DRAFT = "systemTaskManagementTaskTypeDraft",
  SYSTEM_TASK_MANAGEMENT_TASK_DRAFT = "systemTaskManagementTaskDraft",
  SYSTEM_TASK_MANAGEMENT_NOTE_TYPE_DRAFT = "systemTaskManagementNoteTypeDraft",
  SYSTEM_TASK_MANAGEMENT_TASK_NOTE_DRAFT = "systemTaskManagementTaskNoteDraft",
  SYSTEM_TASK_MANAGEMENT_RECURRING_TASK = "systemTaskManagementRecurringTask",
  FIREBLOCKS_WALLET_FORM_DRAFT = "fireblocksWalletFormDraft",
  HR_DOCUMENT_LIST_DRAFT = "hrDocumentListDraft",
  HR_EMPLOYEE_DRAFT = "hrEmployeeDraft",
  HR_EMPLOYEE_DOCUMENTS_DRAFT = "hrEmployeeDocumentsDraft",
  HR_EMPLOYEE_DOC_LIST_DOCS_DRAFT = "hrEmployeeDocListDocsDraft",
  HR_HOLIDAY_CALENDAR_DRAFT = "hrHolidayCalendarDraft",
  HR_HOLIDAY_CALENDAR_DATES_DRAFT = "hrHolidayCalendarDatesDraft",
  HR_VENTURE_POINTS_DRAFT = "hrVenturePointsDraft",
  HR_VENTURE_PERFORMANCE_DRAFT = "hrVenturePerformanceDraft",
  HR_CAPITAL_FORMATION_TIER_DRAFT = "hrCapitalFormationTierDraft",
  WESTEROS_PWM_DOCUMENTS_DRAFT = "westerosPwmDocumentDraft",
  WESTEROS_PWM_DOCUMENTS_DOCUMENT_DRAFT = "westerosPwmDocumentsDocumentDraft",
  WESTEROS_PWM_NOTE_TYPE_DRAFT = "westerosPwmNoteTypeDraft",
  WESTEROS_PWM_PROFILE_FORM_DRAFT = "westerosPwmProfileFormDraft",
  WESTEROS_PWM_PROFILE_NOTES_DRAFT = "westerosPwmProfileNotesDraft",
  WESTEROS_PWM_PROFILE_ADDITIONAL_DOCS_DRAFT = "westerosPwmProfileAdditionalDocsDraft",
  WESTEROS_PWM_PROFILE_DOC_LIST_DOCS_DRAFT = "westerosPwmProfileDocListsDocsDraft",
  WESTEROS_PWM_PROFILE_CONTRACT_DOCS_DRAFT = "westerosPwmProfileContractDocsDraft",
  WESTEROS_PWM_PROFILE_INVESTOR_UBOS_DRAFT = "westerosPwmProfileInvestorUbosDraft",
  WESTEROS_PWM_TRADING_DRAFT = "westerosPwmTradingDraft",
  WESTEROS_PWM_EXCHANGE_DRAFT = "westerosPwmExchangeDraft",
  WESTEROS_PWM_STRATEGY_DRAFT = "westerosPwmStrategyDraft",
}

export enum QUERY_STRINGS {
  SORT = "sort",
  PAGINATION = "page",
  SEARCH = "search",
  LIMIT_PER_PAGE = "limit",
  FILTERS = "filters",
  GROUP_BY = "group_by",
  SEPARATOR_COMMA = ",",
  SEPARATOR = ":",
  ID_NAME = "idName",
}

export enum DELAY_VALUE_FOR_DEBOUNCE {
  BASIC_DEBOUNCE = 500,
  TABLE_SEARCH_DEBOUNCE = 500,
  DOCUMENT_DESCRIPTION_DEBOUNCE = 500,
  FILTERS_DEBOUNCE = 1000,
  SLIDE_ANIMATION = 40,
}

export enum DELAY_VALUE_FOR_UNMOUNT {
  SELECTIVE_INPUT_DELAY = 196,
  FILTERBAR_DELAY = 225,
}

export enum PAGINATION_RELATED_CONSTANTS {
  DEFAULT_PAGE = 1,
}

export enum SocialNetworks {
  TELEGRAM = "telegram",
  LINE = "line",
  WHATSAPP = "whatsapp",
  TWITTER = "twitter",
  DISCORD = "discord",
  SLACK = "slack",
  LINKEDIN = "linkedin",
  VIBER = "viber",
  SKYPE = "skype",
  FACEBOOK = "facebook",
  GIT = "git",
}

export enum ScopeEnum {
  Create = "CREATE",
  Delete = "DELETE",
  SendEmail = "SEND_EMAIL",
  Update = "UPDATE",
  View = "VIEW",
  Archive = "ARCHIVE",
}

export enum TaskStatusEnum {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  IN_REVIEW = "Review Needed",
  BLOCKED = "Blocked",
  DECLINED = "Declined",
  COMPLETED = "Completed",
}

export enum ResourceEnum {
  SuperAdmin = "SUPER_ADMIN",
  AdvisoryBuyer = "ADVISORY_BUYER",
  AdvisoryProject = "ADVISORY_PROJECT",
  AdvisoryPurchase = "ADVISORY_PURCHASE",
  AdvisoryTranche = "ADVISORY_TRANCHE",
  AdvisoryExitPlan = "ADVISORY_EXIT_PLAN",
  AdvisoryDistribution = "ADVISORY_DISTRIBUTION",
  AdvisoryReport = "ADVISORY_REPORT",
  Announcement = "ANNOUNCEMENT",
  AnnouncementDocument = "ANNOUNCEMENT_DOCUMENT",
  AssetsAsset = "ASSETS_ASSET",
  AssetsExchange = "ASSETS_EXCHANGE",
  AssetsFireblocks = "ASSETS_FIREBLOCKS",
  CapitalMovement = "CAPITAL_MOVEMENT",
  CapitalMovementSwitching = "CAPITAL_MOVEMENT_SWITCHING",
  Country = "COUNTRY",
  CountryPhoneCode = "COUNTRY_PHONE_CODE",
  DigitalAssetConcern = "DIGITAL_ASSET_CONCERN",
  DocumentList = "DOCUMENT_LIST",
  EmailHistory = "EMAIL_HISTORY",
  EmailTemplate = "EMAIL_TEMPLATE",
  EmployeeDashboard = "EMPLOYEE_DASHBOARD",
  ExposureMotivation = "EXPOSURE_MOTIVATION",
  InvestmentCapacity = "INVESTMENT_CAPACITY",
  InvestmentData = "INVESTMENT_DATA",
  InvestmentType = "INVESTMENT_TYPE",
  InvestorData = "INVESTOR_DATA",
  InvestorDocument = "INVESTOR_DOCUMENT",
  InvestorIntakeInterview = "INVESTOR_INTAKE_INTERVIEW",
  InvestorNote = "INVESTOR_NOTE",
  InvestorProfile = "INVESTOR_PROFILE",
  KpiData = "KPI_DATA",
  LeadConversionExpectation = "LEAD_CONVERSION_EXPECTATION",
  LinkAgentDocument = "LINK_AGENT_DOCUMENT",
  LinkAgentKpiData = "LINK_AGENT_KPI_DATA",
  LinkAgentNote = "LINK_AGENT_NOTE",
  LinkAgentPayout = "LINK_AGENT_PAYOUT",
  LinkAgentPayoutDocument = "LINK_AGENT_PAYOUT_DOCUMENT",
  LinkAgentProfile = "LINK_AGENT_PROFILE",
  LinkAgentReport = "LINK_AGENT_REPORT",
  NoteType = "NOTE_TYPE",
  Notification = "NOTIFICATION",
  Permission = "PERMISSION",
  ProductFund = "PRODUCT_FUND",
  ProductFundSeries = "PRODUCT_FUND_SERIES",
  ProjectProfile = "PROJECT_PROFILE",
  Report = "REPORT",
  ReportType = "REPORT_TYPE",
  Resource = "RESOURCE",
  ResourceDocument = "RESOURCE_DOCUMENT",
  Role = "ROLE",
  Snapshot = "SNAPSHOT",
  SourceExchange = "SOURCE_EXCHANGE",
  SourceOfInvestment = "SOURCE_OF_INVESTMENT",
  Statement = "STATEMENT",
  StatementType = "STATEMENT_TYPE",
  SystemTaskManagementTask = "SYSTEM_TASK_MANAGEMENT_TASK",
  SystemTaskManagementTaskType = "SYSTEM_TASK_MANAGEMENT_TASK_TYPE",
  SystemTaskManagementTaskTemplate = "SYSTEM_TASK_MANAGEMENT_TASK_TEMPLATE",
  SystemTaskManagementTaskNoteType = "SYSTEM_TASK_MANAGEMENT_TASK_NOTE_TYPE",
  SystemTaskManagementTaskDocument = "SYSTEM_TASK_MANAGEMENT_TASK_DOCUMENT",
  SystemTaskManagementTaskNote = "SYSTEM_TASK_MANAGEMENT_TASK_NOTE",
  SystemTaskManagementRecurringTask = "SYSTEM_TASK_MANAGEMENT_RECURRING_TASK",
  SystemTaskManagementTaskReporting = "SYSTEM_TASK_MANAGEMENT_TASK_REPORTING",
  Tag = "TAG",
  Token = "TOKEN",
  UpcomingEvent = "UPCOMING_EVENT",
  UpcomingEventDocument = "UPCOMING_EVENT_DOCUMENT",
  User = "USER",
  UserDepartment = "USER_DEPARTMENT",
  VentureBlockchain = "VENTURE_BLOCKCHAIN",
  VentureContactType = "VENTURE_CONTACT_TYPE",
  VentureDocument = "VENTURE_DOCUMENT",
  VentureDocumentList = "VENTURE_DOCUMENT_LIST",
  VentureInvestmentMethod = "VENTURE_INVESTMENT_METHOD",
  VentureNote = "VENTURE_NOTE",
  VentureNoteType = "VENTURE_NOTE_TYPE",
  VentureOriginationSource = "VENTURE_ORIGINATION_SOURCE",
  VentureProjectType = "VENTURE_PROJECT_TYPE",
  VentureSector = "VENTURE_SECTOR",
  VentureTag = "VENTURE_TAG",
  VentureDashboard = "VENTURE_DASHBOARD",
  VentureValuationControl = "VENTURE_VALUATION_CONTROL",
  VentureAssetAttribution = "ASSET_ATTRIBUTION",
  VentureContact = "VENTURE_CONTACT",
  VentureKpi = "VENTURE_KPI",
  VenturePortfolioControl = "VENTURE_PORTFOLIO_CONTROL",
  VentureSnapshots = "VENTURE_SNAPSHOT",
  WeeklyUpdate = "WEEKLY_UPDATE",
  WeeklyUpdateDocument = "WEEKLY_UPDATE_DOCUMENT",
  WesterosClientDocument = "WESTEROS_CLIENT_DOCUMENT",
  WesterosClientNote = "WESTEROS_CLIENT_NOTE",
  WesterosClientProfile = "WESTEROS_CLIENT_PROFILE",
  WesterosDocumentList = "WESTEROS_DOCUMENT_LIST",
  WesterosNoteType = "WESTEROS_NOTE_TYPE",
  WesterosPwmDocumentList = "PWM_DOCUMENT_LIST",
  WesterosPwmNoteType = "PWM_NOTE_TYPE",
  WesterosPwmProfile = "PWM_PROFILE",
  WesterosPwmDocument = "PWM_DOCUMENT",
  WesterosPwmNote = "PWM_NOTE",
  WesterosPwmProfileContract = "PWM_PROFILE_CONTRACT",
  WesterosPwmTrading = "PWM_PROFILE_TRADING",
  WesterosPwmExchange = "PWM_PROFILE_EXCHANGE",
  WesterosPwmStrategy = "PWM_PROFILE_STRATEGY",
  WesterosService = "WESTEROS_SERVICE",
  WesterosTag = "WESTEROS_TAG",
  FireblocksWallet = "FIREBLOCKS_WALLET",
  CapitalMovementDividend = "CAPITAL_MOVEMENT_DIVIDEND",
  HrEmployee = "HR_EMPLOYEE",
  HrEmployeeLeave = "HR_EMPLOYEE_LEAVE",
  HrEmployeeLeaveHistory = "HR_EMPLOYEE_LEAVE_HISTORY",
  HrEmployeeCompensation = "HR_EMPLOYEE_COMPENSATION",
  HrDocument = "HR_DOCUMENT",
  HrDocumentList = "HR_DOCUMENT_LIST",
  HrHolidayCalendar = "HR_HOLIDAY_CALENDAR",
  HrFinancialReport = "HR_FINANCIAL_REPORT",
  HrQuantFundTier = "HR_QUANT_FUND_TIER",
  HrQuantPoints = "HR_QUANT_POINTS",
  HrQuantPerformance = "HR_QUANT_PERFORMANCE",
  HrQuantDistributionHistory = "HR_QUANT_DISTRIBUTION_HISTORY",
  HrVenturePoints = "HR_VENTURE_POINTS",
  HrVenturePool = "HR_VENTURE_POOL",
  HrVentureDistributionHistory = "HR_VENTURE_DISTRIBUTION_HISTORY",
  HrCapitalFormationTier = "HR_CAPITAL_FORMATION_TIER",
  HrCapitalFormationDistributionHistory = "HR_CAPITAL_FORMATION_DISTRIBUTION_HISTORY",
}

export class InvestorTypeEnum extends AbstractEnum {
  public static INDIVIDUAL: 1 = 1;
  public static INSTITUTION: 2 = 2;
  public static CORPORATE: 3 = 3;

  public static choices: { [key: number]: any } = {
    [InvestorTypeEnum.INDIVIDUAL]: "Individual",
    [InvestorTypeEnum.INSTITUTION]: "Institutional",
    [InvestorTypeEnum.CORPORATE]: "Corporate",
  };
}

export class InvestorStatusEnum extends AbstractEnum {
  public static NL: 1 = 1;
  public static P: 2 = 2;
  public static IP: 3 = 3;
  public static IPC: 4 = 4;
  public static PPM: 5 = 5;
  public static CS: 6 = 6;
  public static DIP: 7 = 7;
  public static DOC: 8 = 8;
  public static AI: 9 = 9;
  public static EI: 10 = 10;
  public static IN: 11 = 11;

  public static choices = {
    [InvestorStatusEnum.NL]: "New Lead",
    [InvestorStatusEnum.P]: "Prospect",
    [InvestorStatusEnum.IP]: "Investor Profile Complete",
    [InvestorStatusEnum.IPC]: "Initial Presentation Complete",
    [InvestorStatusEnum.PPM]: "Post-Presentation Meeting Complete",
    [InvestorStatusEnum.CS]: "Commitment Secured",
    [InvestorStatusEnum.DIP]: "Documentation in Process",
    [InvestorStatusEnum.DOC]: "Documentation Complete",
    [InvestorStatusEnum.AI]: "Active Investor",
    [InvestorStatusEnum.EI]: "Exited Investor",
    [InvestorStatusEnum.IN]: "Inactive",
  };
}

export class InvestmentStatusEnum extends AbstractEnum {
  public static UNCOMMITTED: 1 = 1;
  public static COMMITTED: 2 = 2;
  public static INVESTMENT_PENDING: 3 = 3;
  public static INVESTMENT_SECURED: 4 = 4;

  public static choices: { [key: number]: any } = {
    [InvestmentStatusEnum.UNCOMMITTED]: "Uncommitted",
    [InvestmentStatusEnum.COMMITTED]: "Committed",
    [InvestmentStatusEnum.INVESTMENT_PENDING]: "Investment pending",
    [InvestmentStatusEnum.INVESTMENT_SECURED]: "Investment secured",
  };
}

export class InvestorClassEnum extends AbstractEnum {
  public static INDIVIDUAL: 1 = 1;
  public static CORPORATION: 2 = 2;
  public static FAMILY_OFFICE: 3 = 3;
  public static INSTITUTION: 4 = 4;

  public static choices: { [key: number]: any } = {
    [InvestorClassEnum.INDIVIDUAL]: "Individual",
    [InvestorClassEnum.INSTITUTION]: "Institutional",
    [InvestorClassEnum.CORPORATION]: "Corporation",
    [InvestorClassEnum.FAMILY_OFFICE]: "Family Office",
  };
}

export enum StorageEnum {
  TMP = "tmp",
  GENERAL = "general",
  BACKUP = "backup",
  ETERNAL = "eternal",
}

export enum CapitalMovementTypeEnum {
  ADDON_SUBSCRIPTION = "Add-On Subscription",
  DIVIDEND_INVESTMENT_SUBSCRIPTION = "Dividend Investment Subscription",
  DIVIDEND_PAYOUT = "Dividend Payout",
  DISTRIBUTION = "Distribution",
  FTX_SIDE_POCKET = "FTX Side Pocket",
  INITIAL_SUBSCRIPTION = "Initial Subscription",
  REDEMPTION = "Redemption",
  FTX_SIDE_POCKET_REDEMPTION = "Redemption from FTX Side Pocket",
  REDEMPTION_SIDE_POCKET = "Redemption due to Side Pocket",
  SERIES_SWITCHES = "Series switches",
  // subscription not included in Total Subscription calculation
  SUBSCRIPTION = "Subscription",
}

export enum InvestmentTypeEnum {
  FIAT = "Fiat",
  IN_KIND = "InKind",
}

export enum StatusPalette {
  NEW_LEAD = "#BAE4FF",
  PROSPECT = "#7CCBFF",
  IP = "#319DE3",
  IC = "#2576AB",
  PPM = "#1D5980",
  CS = "#154463",
  COMMITTED = "#0E2C40",
  ACTIVE = "#06121B",
}

export enum ArtificialDelay {
  CHART_BE_RESPONSE = 500,
  REPORTING_EXPORT = 0,
  FILE_DOWNLOAD_TIMEOUT = 3000,
  TILL_EXPAND_COLLAPSIBLE_SECTIONS = 1000,
  MODAL_SWITCH = 100,
  SEND_EMAIL_FILTER_DELETE = 1000,
}

export enum SnapshotTypeEnum {
  EXCHANGE = "Exchange",
  FIREBLOCKS = "Fireblocks",
}

export enum ClientStatusEnum {
  CLIENT_ACTIVE = "Client active",
  CLIENT_DOCUMENTATION = "Client documentation",
  CLIENT_INACTIVE = "Client inactive",
  CLIENT_ONBOARDING = "Client onboarding",
}

export enum LiquidSupplyEnum {
  LESS_TEN_PERCENT = "Less 10%",
  TEN_PERCENT = "10%",
  TWENTY_PERCENT = "20%",
  MORE_THIRTY_PERCENT = "More 30%",
  UNDECIDED = "Undecided",
}

export enum SourceExchangeTypeEnum {
  BANK = "Bank",
  CUSTODY = "Custody",
  EXCHANGE_CEX = "Exchange-CEX",
  EXCHANGE_DEX = "Exchange-DEX",
  WALLET = "Wallet",
}

export enum ProductFundDividendFrequencyEnum {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export enum NotificationTypeEnum {
  ANNOUNCEMENT_ADDED = "Announcement added",
  CAPITAL_MOVEMENT_ADDED = "Capital movement added",
  INVESTOR_UPDATED = "Investor updated",
  INVESTOR_DOCUMENT_UPDATED = "Investor document updated",
  REPORT_ADDED = "Report added",
  STATEMENT_ADDED = "Statement added",
  TASK_CREATED = "Task Created",
  TASK_UPDATED = "Task Updated",
  TASK_DELETED = "Task Deleted",
  TASK_NOTE_CREATED = "Task Note Created",
  TASK_NOTE_UPDATED = "Task Note Updated",
  UPCOMING_EVENT_ADDED = "Upcoming event added",
  WEEKLY_UPDATE_ADDED = "Weekly update added",
}

export enum EventEnum {
  NOTIFICATION = "notification",
  ERROR = "error",
}

export enum LinkAgentPayoutInvestmentStatusEnum {
  Paid = "PAID",
  Pending = "PENDING",
}

export enum CommissionStatusEnum {
  NOT_SEASONED = "Not seasoned",
  PENDING = "Pending",
  PAID = "Paid",
}

export enum PaymentTypeEnum {
  FIAT = "Fiat",
  IN_KIND = "In kind",
}

export enum CapitalTrackingStatusEnum {
  AI = "Active investor",
  EI = "Exited investor",
  PROSPECT = "Prospect",
}

export enum UserInteractionTypeEnum {
  INVESTOR = "Investor",
  INTERNAL = "Internal",
}

export enum DepartmentEnum {
  ADMINISTRATION = "Administration",
  CAPITAL_FORMATION = "Capital Formation",
  DEFI = "DeFi",
  FINANCE = "Finance",
  IT_DEVELOPMENT = "IT & Development",
  MANAGEMENT = "Management",
  QUANTITATIVE = "Quantitative",
  SERVICE_PROVIDER = "Service Provider",
  VENTURE = "Venture",
}

export enum RecurringTaskScheduleEnum {
  DAY = "Daily",
  WEEK = "Weekly",
  MONTH = "Monthly",
  QUARTER = "Quarterly",
  YEAR = "Yearly",
}

export enum EmployeeCurrencyEnum {
  USD = "USD",
  THB = "THB",
}

import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrCapitalFormationTierGetByIdDocument,
  HrCapitalFormationTierCreateDocument,
  HrCapitalFormationTierUpdateDocument,
  HrCapitalFormationTierDeleteDocument,
  HrCapitalFormationTierListPaginatedDocument,
} from "api/generated";

import {
  IHrCapitalFormationTierModel,
  IHrCapitalFormationTierUpdateVars,
  IHrCapitalFormationTierCreateVars,
  IHrCapitalFormationTierPaginatedVars,
  IHrCapitalFormationTierPaginatedResponse,
} from "./types";
import { Optional } from "config";

export async function getHrCapitalFormationTierPaginated(
  variables: IHrCapitalFormationTierPaginatedVars
): Promise<Optional<IHrCapitalFormationTierPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrCapitalFormationTierListPaginatedDocument,
      variables,
    });
    return res?.data?.hrCapitalFormationTierListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getHrCapitalFormationTierById(
  id: number
): Promise<IHrCapitalFormationTierModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrCapitalFormationTierGetByIdDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrCapitalFormationTierGetById ?? {};
  } catch (error) {
    throw error;
  }
}

export async function createHrCapitalFormationTier(
  input: IHrCapitalFormationTierCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrCapitalFormationTierCreateDocument,
      variables: input,
    });
    return res?.data?.hrCapitalFormationTierCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateHrCapitalFormationTier(
  input: IHrCapitalFormationTierUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrCapitalFormationTierUpdateDocument,
      variables: input,
    });
    return res?.data?.hrCapitalFormationTierUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrCapitalFormationTier(
  id: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrCapitalFormationTierDeleteDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrCapitalFormationTierDelete ?? false;
  } catch (error) {
    throw error;
  }
}

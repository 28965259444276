import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IHrCapitalFormationDistributionHistoryPaginatedResponse,
  getHrCapitalFormationDistributionHistoryPaginated,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { HrCapitalFormationDistributionModalMode } from "./enums";
import { IHrCapitalFormationDistributionModalData } from "./types";

const initialState = {
  hrCapitalFormationDistributionData: null as Nullable<
    IHrCapitalFormationDistributionHistoryPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: HrCapitalFormationDistributionModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrCapitalFormationDistributionModalData,
};

export const fetchHrCapitalFormationDistributionList = createAsyncThunk(
  "hr/fetchHrCapitalFormationDistributionList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getHrCapitalFormationDistributionHistoryPaginated(
      filters
    );
    return response;
  }
);

const hrCapitalFormationDistributionSlice = createSlice({
  name: "hrCapitalFormationDistribution",
  initialState,
  reducers: {
    setHrCapitalFormationDistributionModalData: (
      state,
      action: PayloadAction<IHrCapitalFormationDistributionModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrCapitalFormationDistributionModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrCapitalFormationDistributionModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchHrCapitalFormationDistributionList.fulfilled,
      (state, action) => {
        if (!action.payload) return;
        return {
          ...state,
          hrCapitalFormationDistributionData: action.payload
            ?.edges as IHrCapitalFormationDistributionHistoryPaginatedResponse["edges"],
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(
      fetchHrCapitalFormationDistributionList.pending,
      (state) => {
        return {
          ...state,
          loading: true,
        };
      }
    );
  },
});

export const {
  setHrCapitalFormationDistributionModalData,
  closeHrCapitalFormationDistributionModal,
  setHrCapitalFormationDistributionModalLoading,
} = hrCapitalFormationDistributionSlice.actions;
export default hrCapitalFormationDistributionSlice.reducer;

import { ICommonModalData } from "config";

export enum WesterosPwmTradingModalMode {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
}

export interface IWesterosPwmTradingModalData
  extends ICommonModalData<WesterosPwmTradingModalMode> {
  id?: number;
  loading: boolean;
}

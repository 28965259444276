import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrVenturePointsGetByEmployeeSettingDocument,
  HrVenturePointsCreateDocument,
  HrVenturePointsDeleteDocument,
  HrVenturePointsListPaginatedDocument,
  HrVenturePointsUpdateDocument,
  HrVenturePointsDeleteByEmployeeSettingDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IHrVenturePointsPaginatedResponse,
  IHrVenturePointsUpdateVars,
  IHrVenturePointsCreateVars,
  IHrVenturePointsPaginatedVars,
  IHrVenturePointsResponse,
} from "./types";

export async function getHrVenturePointsListPaginated(
  input: IHrVenturePointsPaginatedVars
): Promise<Optional<IHrVenturePointsPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVenturePointsListPaginatedDocument,
      variables: input,
    });
    return res?.data?.hrVenturePointsListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getHrVenturePointsById(
  employeeId: number,
  settingsId: number
): Promise<IHrVenturePointsResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVenturePointsGetByEmployeeSettingDocument,
      variables: {
        employeeId,
        settingsId,
      },
    });
    return res?.data?.hrVenturePointsGetByEmployeeSetting ?? {};
  } catch (error) {
    throw error;
  }
}

export async function createHrVenturePoints(
  input: IHrVenturePointsCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePointsCreateDocument,
      variables: input,
    });
    return res?.data?.hrVenturePointsCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateHrVenturePoints(
  input: IHrVenturePointsUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePointsUpdateDocument,
      variables: input,
    });
    return res?.data?.hrVenturePointsUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrVenturePoints(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePointsDeleteDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrVenturePointsDelete ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteByEmployeeSettingHrVenturePoints(
  employeeId: number,
  settingsId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePointsDeleteByEmployeeSettingDocument,
      variables: {
        employeeId,
        settingsId,
      },
    });
    return res?.data?.hrVenturePointsDeleteByEmployeeSetting ?? false;
  } catch (error) {
    throw error;
  }
}

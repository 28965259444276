import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  PwmProfileTradingGetByIdDocument,
  PwmProfileTradingListPaginatedDocument,
  PwmProfileTradingCreateDocument,
  PwmProfileTradingUpdateDocument,
  PwmProfileTradingDeleteDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IPwmProfileTradingCreateVars,
  IPwmProfileTradingModel,
  IPwmProfileTradingPaginatedResponse,
  IPwmProfileTradingPaginatedVars,
  IPwmProfileTradingUpdateVars,
} from "./types";

export async function getPwmProfileTrading(
  id: number
): Promise<Optional<IPwmProfileTradingModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileTradingGetByIdDocument,
      variables: {
        id,
      },
    });
    return res?.data?.pwmProfileTradingGetById;
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfileTradingPaginated(
  variables: IPwmProfileTradingPaginatedVars
): Promise<Optional<IPwmProfileTradingPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileTradingListPaginatedDocument,
      variables,
    });
    return res?.data?.pwmProfileTradingListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createPwmProfileTrading(
  variables: IPwmProfileTradingCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileTradingCreateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileTradingCreate);
  } catch (error) {
    throw error;
  }
}

export async function updatePwmProfileTrading(
  variables: IPwmProfileTradingUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileTradingUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileTradingUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deletePwmProfileTrading(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileTradingDeleteDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.pwmProfileTradingDelete);
  } catch (error) {
    throw error;
  }
}

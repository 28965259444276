import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrQuantDistributionHistoryGetByEmployeeDocument,
  HrQuantDistributionHistoryPaginatedDocument,
  HrQuantDistributionHistoryCreateDocument,
  HrQuantDistributionHistoryUpdateDocument,
  HrQuantDistributionHistoryDeleteDocument,
  HrQuantDistributionCurrencyListDocument,
} from "api/generated";
import { ICommonListModel } from "api/services/types";
import { Optional } from "config";

import {
  IHrQuantDistributionHistoryModel,
  IHrQuantDistributionHistoryCreateVars,
  IHrQuantDistributionHistoryPaginatedResponse,
  IHrQuantDistributionHistoryPaginatedVars,
  IHrQuantDistributionHistoryUpdateVars,
} from "./types";

export async function getHrQuantDistributionCurrencyList(): Promise<
  ICommonListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrQuantDistributionCurrencyListDocument,
    });
    return res?.data?.hrQuantDistributionCurrencyList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrQuantDistributionHistory(
  employeeId: number
): Promise<Optional<IHrQuantDistributionHistoryModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrQuantDistributionHistoryGetByEmployeeDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrQuantDistributionHistoryGetByEmployee;
  } catch (error) {
    throw error;
  }
}

export async function getHrQuantDistributionHistoryPaginated(
  variables: IHrQuantDistributionHistoryPaginatedVars
): Promise<Optional<IHrQuantDistributionHistoryPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrQuantDistributionHistoryPaginatedDocument,
      variables,
    });
    return res?.data?.hrQuantDistributionHistoryListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createHrQuantDistributionHistory(
  variables: IHrQuantDistributionHistoryCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrQuantDistributionHistoryCreateDocument,
      variables,
    });
    return Boolean(res?.data?.hrQuantDistributionHistoryCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateHrQuantDistributionHistory(
  variables: IHrQuantDistributionHistoryUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrQuantDistributionHistoryUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.hrQuantDistributionHistoryUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteHrQuantDistributionHistory(
  id: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrQuantDistributionHistoryDeleteDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.hrQuantDistributionHistoryDelete);
  } catch (error) {
    throw error;
  }
}

import {
  NoteTypeCreateDocument,
  NoteTypeDeleteDocument,
  NoteTypeGetByIdDocument,
  NoteTypeListDocument,
  NoteTypesPaginatedDocument,
  NoteTypeUpdateDocument,
} from "api/generated";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import { ResourceEnum, ScopeEnum } from "config";

import {
  ICreateNoteTypeVars,
  IGetNotesListArgs,
  IGetNoteTypeByIdReturnType,
  INotesListReturnType,
  INoteTypeModel,
  IUpdateNoteTypeVars,
} from "../types";

export async function getNotesTypeList(
  variables: IGetNotesListArgs
): Promise<INotesListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: NoteTypesPaginatedDocument,
      variables,
    });
    return res?.data?.noteTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getNotesTypesForFilters(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<INoteTypeModel, "noteTypeId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: NoteTypeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.noteTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function createNoteType(
  variables: ICreateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: NoteTypeCreateDocument,
      variables,
    });
    return res?.data?.noteTypeCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function getNoteTypeById(
  noteTypeId: number
): Promise<IGetNoteTypeByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: NoteTypeGetByIdDocument,
      variables: {
        noteTypeId,
      },
    });
    return res?.data?.noteTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateNoteTypeById(
  variables: IUpdateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: NoteTypeUpdateDocument,
      variables,
    });
    return res?.data?.noteTypeUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteNoteType(noteTypeId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: NoteTypeDeleteDocument,
      variables: {
        noteTypeId,
      },
    });
    return Boolean(res?.data?.noteTypeDelete);
  } catch (e) {
    throw e;
  }
}

import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  PwmDocumentListCreateDocument,
  PwmDocumentListDeleteDocument,
  PwmDocumentListGetByIdDocument,
  PwmDocumentListPaginatedDocument,
  PwmDocumentListListDocument,
  PwmDocumentListUpdateDocument,
} from "api/generated";
import { Optional } from "config";

import {
  DocumentListByIdResponse,
  ICreateDocumentListArgs,
  IDocumentListArgs,
  IDocumentListModel,
  IDocumentListResponse,
  IDocumentListUpdateArgs,
} from "../../library/document-lists/types";
import { PwmDocumentListModel } from "./types";

export async function getPwmDocumentListPaginated(
  variables: IDocumentListArgs
): Promise<IDocumentListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmDocumentListPaginatedDocument,
      variables,
    });
    return res?.data?.pwmDocumentListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createPwmDocumentList(
  variables: ICreateDocumentListArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmDocumentListCreateDocument,
      variables,
    });
    return res?.data?.pwmDocumentListCreate;
  } catch (error) {
    throw error;
  }
}

export async function getPwmDocumentList(
  documentListId: number
): Promise<DocumentListByIdResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmDocumentListGetByIdDocument,
      variables: {
        documentListId,
      },
    });
    return res?.data?.pwmDocumentListGetById;
  } catch (error) {
    throw error;
  }
}

export async function updatePwmDocumentList(
  variables: IDocumentListUpdateArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmDocumentListUpdateDocument,
      variables,
    });
    return res?.data?.pwmDocumentListUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deletePwmDocumentList(
  documentListId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmDocumentListDeleteDocument,
      variables: {
        documentListId,
      },
    });
    return Boolean(res?.data?.pwmDocumentListDelete);
  } catch (error) {
    throw error;
  }
}

export async function getPwmDocumentLists(): Promise<PwmDocumentListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmDocumentListListDocument,
    });
    return res?.data?.pwmDocumentListList ?? [];
  } catch (error) {
    throw error;
  }
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IHrVenturePoolPaginatedResponse,
  getHrVenturePoolListPaginated,
  getHrVenturePoolTotal,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { HrVenturePerformanceModalMode } from "./enums";
import { IHrVenturePerformanceModalData } from "./types";

const initialState = {
  hrVenturePerformanceData: null as Nullable<
    IHrVenturePoolPaginatedResponse["edges"]
  >,
  totalCount: 1,
  totalValue: 0,
  loading: false,
  modalData: {
    mode: HrVenturePerformanceModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrVenturePerformanceModalData,
};

export const fetchHrVenturePerformanceList = createAsyncThunk(
  "hr/fetchHrVenturePerformanceList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const [list, total] = await Promise.all([
      getHrVenturePoolListPaginated(filters),
      getHrVenturePoolTotal(filters?.filters),
    ]);
    return { list, total };
  }
);

const hrVenturePerformanceSlice = createSlice({
  name: "hrVenturePerformance",
  initialState,
  reducers: {
    setHrVenturePerformanceModalData: (
      state,
      action: PayloadAction<IHrVenturePerformanceModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrVenturePerformanceModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrVenturePerformanceModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchHrVenturePerformanceList.fulfilled,
      (state, action) => {
        if (!action.payload) return;
        return {
          ...state,
          hrVenturePerformanceData: action.payload?.list
            ?.edges as IHrVenturePoolPaginatedResponse["edges"],
          totalCount: action.payload?.list?.totalCount ?? 1,
          totalValue: action.payload?.total ?? 0,
          loading: false,
        };
      }
    );
    builder.addCase(fetchHrVenturePerformanceList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setHrVenturePerformanceModalData,
  closeHrVenturePerformanceModal,
  setHrVenturePerformanceModalLoading,
} = hrVenturePerformanceSlice.actions;
export default hrVenturePerformanceSlice.reducer;

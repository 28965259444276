import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getPwmProfileStrategyPaginated,
  IPwmProfileStrategyPaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

import {
  IWesterosPwmStrategyModalData,
  WesterosPwmStrategyModalMode,
} from "./types";

const initialState = {
  list: null as Nullable<IPwmProfileStrategyPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: WesterosPwmStrategyModalMode.VIEW,
  } as IWesterosPwmStrategyModalData,
};

export const fetchWesterosPwmStrategy = createAsyncThunk(
  "westeros-pwm-trading/fetchWesterosPwmStrategy",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getPwmProfileStrategyPaginated(filters);
    return response;
  }
);

const westerosPwmStrategySlice = createSlice({
  name: "westeros-pwm-trading",
  initialState,
  reducers: {
    setWesterosPwmStrategyModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeWesterosPwmStrategyModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosPwmStrategy.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        list: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchWesterosPwmStrategy.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setWesterosPwmStrategyModalData,
  closeWesterosPwmStrategyModal,
} = westerosPwmStrategySlice.actions;
export default westerosPwmStrategySlice.reducer;

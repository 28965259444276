import {
  InvestmentDataExportDocument,
  InvestmentDataPaginatedDocument,
  InvestmentDataExportFileTypeDocument,
  InvestmentDataAggregatedByAssignedDocument,
  InvestmentDataAggregatedByClassDocument,
  InvestmentDataGroupedByDateDocument,
  InvestmentDataAggregatedByProductFundDocument,
  InvestmentDataAggregatedByInvestorDocument,
  InvestmentDataAggregatedByStatusDocument,
  InvestmentDataInvestorGeneralDocument,
  InvestmentDataProductFundsDocument,
  InvestmentDataByInvestorGroupedByProductFundDocument,
  InvestmentDataCalculateRoiDocument,
  InvestmentDataCalculateCurrentStatsDocument,
} from "api/generated";
import { PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IGetReportingListGroupedByAssigneeReturnType,
  IGetReportingListGroupedByClassReturnType,
  IGetReportingListGroupedByFundsReturnType,
  IGetReportingListGroupedByInvestorReturnType,
  IGetReportingListGroupedByStatusReturnType,
  IGetReportingChartDataArgs,
  IGetReportingGeneralDataByIdArgs,
  IGetReportingGeneralDataByIdModel,
  IGetReportingListArgs,
  IGetReportingListReturnType,
  IExportReportingDataArgs,
  IReportingGroupedByDateReturnType,
  IInvestmentDataCalculatedDataByInvestorProfileVars,
  IReportingByInvestorUserGroupedByProductFundReturnType,
} from "./types";
import { IProductFundModel } from "../../library/product-fund/types";
import {
  IInvestorCurrentPerformanceResponse,
  IInvestorPerformanceCalculateRoiResponse,
} from "../../user-portal/investor/dashboard";

export async function getReportingList(
  variables: IGetReportingListArgs
): Promise<IGetReportingListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataPaginatedDocument,
      variables,
    });
    return res?.data?.investmentDataPaginated;
  } catch (e) {
    throw e;
  }
}

export async function getReportingListGroupedByInvestor(
  variables: IGetReportingListArgs
): Promise<IGetReportingListGroupedByInvestorReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataAggregatedByInvestorDocument,
      variables,
    });
    return res?.data?.investmentDataAggregatedByInvestorPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReportingListGroupedByStatus(
  variables: IGetReportingListArgs
): Promise<IGetReportingListGroupedByStatusReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataAggregatedByStatusDocument,
      variables,
    });
    return res?.data?.investmentDataAggregatedByStatusPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReportingListGroupedByClass(
  variables: IGetReportingListArgs
): Promise<IGetReportingListGroupedByClassReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataAggregatedByClassDocument,
      variables,
    });
    return res?.data?.investmentDataAggregatedByClassPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReportingListGroupedByFunds(
  variables: IGetReportingListArgs
): Promise<IGetReportingListGroupedByFundsReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataAggregatedByProductFundDocument,
      variables,
    });
    return res?.data?.investmentDataAggregatedByProductFundPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReportingListGroupedByAssignee(
  variables: IGetReportingListArgs
): Promise<IGetReportingListGroupedByAssigneeReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataAggregatedByAssignedDocument,
      variables,
    });
    return res?.data?.investmentDataAggregatedByAssignedPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReportingGeneralDataById(
  variables: IGetReportingGeneralDataByIdArgs
): Promise<PartialNullable<IGetReportingGeneralDataByIdModel>[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataInvestorGeneralDocument,
      variables,
    });
    return res?.data?.investmentDataInvestorGeneral ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getReportingChartData(
  variables: IGetReportingChartDataArgs
): Promise<IReportingGroupedByDateReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataGroupedByDateDocument,
      variables,
    });
    if (!res?.data?.investmentDataGroupedByDate) {
      return res?.data?.investmentDataGroupedByDate;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...data } = res?.data?.investmentDataGroupedByDate;
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getReportingExportFileTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataExportFileTypeDocument,
    });
    return res?.data?.investmentDataExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportReportingData(
  variables: IExportReportingDataArgs
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataExportDocument,
      variables,
    });
    return res?.data?.investmentDataExport;
  } catch (error) {
    throw error;
  }
}

export async function getReportingProductFunds(
  investorProfileId: number
): Promise<Pick<IProductFundModel, "name" | "id">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataProductFundsDocument,
      variables: {
        investorProfileId,
      },
    });
    return res?.data?.investmentDataProductFunds ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getInvestmentDataByInvestorGroupedByProductFund(
  investorId: number
): Promise<IReportingByInvestorUserGroupedByProductFundReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataByInvestorGroupedByProductFundDocument,
      variables: { investorId },
    });
    return res?.data?.investmentDataByInvestorGroupedByProductFund;
  } catch (error) {
    throw error;
  }
}

export async function getInvestmentDataCalculateRoiByInvestorProfile(
  variables: IInvestmentDataCalculatedDataByInvestorProfileVars
): Promise<IInvestorPerformanceCalculateRoiResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataCalculateRoiDocument,
      variables,
    });
    return res?.data?.investmentDataCalculateRoi ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getInvestmentDataCalculateCurrentStatsByInvestorProfile(
  variables: IInvestmentDataCalculatedDataByInvestorProfileVars
): Promise<IInvestorCurrentPerformanceResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentDataCalculateCurrentStatsDocument,
      variables,
    });
    return res?.data?.investmentDataCalculateCurrentStats ?? [];
  } catch (error) {
    throw error;
  }
}

import {
  AssetAttributionExitListByProjectProfileDocument,
  ProjectProfileAddTagDocument,
  ProjectProfileCreateDocument,
  ProjectProfileGetByIdDocument,
  ProjectProfileListByProductFundDocument,
  ProjectProfileListCommitmentApprovalsDocument,
  ProjectProfileListDealStructuresDocument,
  ProjectProfileListDealTypesDocument,
  ProjectProfileListDocument,
  ProjectProfileListDueDiligenceTypesDocument,
  ProjectProfileListInvestmentCurrenciesDocument,
  ProjectProfileListPostInvestmentStatusesDocument,
  ProjectProfileListStagesDocument,
  ProjectProfileRemoveTagDocument,
  ProjectProfilesPaginatedDocument,
  ProjectProfileUpdateDocument,
  VentureDocumentDownloadDocument,
  VentureDocumentUploadDocument,
  VentureNoteCreateDocument,
  VentureNoteDeleteDocument,
  VentureNotesDocumentDeleteDocument,
  VentureNotesDocumentDownloadDocument,
  VentureNotesDocumentUploadDocument,
  VentureNoteUpdateDocument,
  VentureNoteVerifyDocument,
  VentureProjectProfileArchiveDocument,
  VentureProjectProfileDeleteDocument,
  VentureProjectProfileListAgreementTypesDocument,
  VentureProjectProfileUnarchiveDocument,
  VentureProjectProfileListAvailableStagesDocument,
  VentureProjectProfileListPortfolioDealByProductFundDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";
import {
  NotificationDuration,
  NotificationMode,
  showSnackBar,
  store,
} from "store";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IAssetAttributionsExitForProjectProfileVars,
  IAssetAttributionsForProjectProfileResponse,
  ICommitmentApproval,
  ICreateProjectProfileNoteVars,
  IDealStructure,
  IDealType,
  IDueDiligenceType,
  IInvestmentCurrency,
  IPostInvestmentStatus,
  IProjectProfileCreateVars,
  IProjectProfileListByProductFundVars,
  IProjectProfileModel,
  IProjectProfilePaginatedResponse,
  IProjectProfilesListVars,
  IProjectProfilesPaginatedVars,
  IProjectProfileUpdateVars,
  IStage,
  IUpdateProjectProfileNoteVars,
  IVentureNoteDocumentModel,
  IVentureNoteModel,
  ProjectProfileCreateUpdateResponse,
  ProjectProfileListByProductFundResponse,
  ProjectProfilesListResponse,
  UpdateProjectProfileNoteResponse,
  VentureDocumentCreateResponse,
} from "./types";
import { IFile } from "../../types";

export async function getProjectProfilesPaginated(
  variables: IProjectProfilesPaginatedVars
): Promise<IProjectProfilePaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfilesPaginatedDocument,
      variables,
    });
    return res?.data?.ventureProjectProfilesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createProjectProfile(
  variables: IProjectProfileCreateVars
): Promise<ProjectProfileCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ProjectProfileCreateDocument,
      variables,
    });
    return res?.data?.ventureProjectProfileCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateProjectProfile(
  variables: IProjectProfileUpdateVars
): Promise<ProjectProfileCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ProjectProfileUpdateDocument,
      variables,
    });
    return res?.data?.ventureProjectProfileUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getProjectProfile(
  projectProfileId: number
): Promise<PartialNullable<IProjectProfileModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileGetByIdDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.ventureProjectProfileGetById;
  } catch (error) {
    throw error;
  }
}

export async function getProjectProfiles(
  variables: IProjectProfilesListVars
): Promise<ProjectProfilesListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListDocument,
      variables,
    });
    return res?.data?.ventureProjectProfileList;
  } catch (error) {
    throw error;
  }
}

export async function getProjectProfilesByProductFund(
  variables: IProjectProfileListByProductFundVars
): Promise<ProjectProfileListByProductFundResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListByProductFundDocument,
      variables,
    });
    return res?.data?.ventureProjectProfileListByProductFund;
  } catch (error) {
    throw error;
  }
}

export async function getProjectProfilesPortfolioDealByProductFund(
  productFundId: number[]
): Promise<ProjectProfileListByProductFundResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectProfileListPortfolioDealByProductFundDocument,
      variables: {
        productFundId,
      },
    });
    return res?.data?.ventureProjectProfileListPortfolioDealByProductFund;
  } catch (error) {
    throw error;
  }
}

export async function createProjectProfileNote(
  variables: ICreateProjectProfileNoteVars
): Promise<Optional<PartialNullable<IVentureNoteModel>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteCreateDocument,
      variables,
    });
    return res?.data?.ventureNoteCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateProjectProfileNote(
  variables: IUpdateProjectProfileNoteVars
): Promise<UpdateProjectProfileNoteResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteUpdateDocument,
      variables,
    });
    return res?.data?.ventureNoteUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteProjectProfileNote(
  ventureNoteId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteDeleteDocument,
      variables: { ventureNoteId },
    });
    return Boolean(res?.data?.ventureNoteDelete);
  } catch (error) {
    throw error;
  }
}

export async function verifyProjectProfileNote(
  ventureNoteId: number
): Promise<Optional<Pick<IVentureNoteModel, "isLocked">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteVerifyDocument,
      variables: { ventureNoteId },
    });
    return res?.data?.ventureNoteVerify;
  } catch (error) {
    throw error;
  }
}

export async function uploadProjectProfileNoteDocument(
  variables: IFile
): Promise<Optional<IVentureNoteDocumentModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNotesDocumentUploadDocument,
      variables,
    });
    return res?.data?.ventureNotesDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadProjectProfileNoteDocument(
  ventureDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNotesDocumentDownloadDocument,
      variables: { ventureDocumentId },
    });
    return res?.data?.ventureNotesDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function deleteProjectProfileNoteDocument(
  ventureDocumentId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNotesDocumentDeleteDocument,
      variables: { ventureDocumentId },
    });
    return Boolean(res?.data?.ventureNotesDocumentDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadVentureDocument(
  variables: IFile
): Promise<Optional<VentureDocumentCreateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureDocumentUploadDocument,
      variables,
    });
    return res?.data?.ventureDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadVentureDocument(
  ventureDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureDocumentDownloadDocument,
      variables: { ventureDocumentId },
    });
    return res?.data?.ventureDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function addProjectProfileTag(
  projectProfileId: number,
  tagId: number
): Promise<Optional<Pick<IProjectProfileModel, "projectProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ProjectProfileAddTagDocument,
      variables: { projectProfileId, tagId },
    });
    return res?.data?.ventureProjectProfileAddTag;
  } catch (error) {
    throw error;
  }
}

export async function removeProjectProfileTag(
  projectProfileId: number,
  tagId: number
): Promise<Optional<Pick<IProjectProfileModel, "projectProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ProjectProfileRemoveTagDocument,
      variables: { projectProfileId, tagId },
    });
    return res?.data?.ventureProjectProfileRemoveTag;
  } catch (error) {
    throw error;
  }
}

export async function getAssetAttributionsExitsForProjectProfile(
  variables: IAssetAttributionsExitForProjectProfileVars
): Promise<PartialNullable<IAssetAttributionsForProjectProfileResponse[]>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionExitListByProjectProfileDocument,
      variables,
    });
    return res?.data?.assetAttributionExitListByProjectProfile ?? { data: [] };
  } catch (error) {
    throw error;
  }
}

export async function getAvailableStagesForProjectProfile(
  projectProfileId?: number
): Promise<IStage[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectProfileListAvailableStagesDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.ventureProjectProfileListAvailableStages ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getStagesForProjectProfile(): Promise<IStage[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListStagesDocument,
    });
    return res?.data?.ventureProjectProfileListStages ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getVentureProjectProfileListAgreementTypes(): Promise<
  { id: number; name: string }[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectProfileListAgreementTypesDocument,
    });
    return res?.data?.ventureProjectProfileListAgreementTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDealTypesForProjectProfile(): Promise<IDealType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListDealTypesDocument,
    });
    return res?.data?.ventureProjectProfileListDealTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDueDiligenceTypesForProjectProfile(): Promise<
  IDueDiligenceType[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListDueDiligenceTypesDocument,
    });
    return res?.data?.ventureProjectProfileListDueDiligenceTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getPostInvestmentStatusesForProjectProfile(): Promise<
  IPostInvestmentStatus[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListPostInvestmentStatusesDocument,
    });
    return res?.data?.ventureProjectProfileListPostInvestmentStatuses ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getCommitmentApprovalsForProjectProfile(): Promise<
  ICommitmentApproval[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListCommitmentApprovalsDocument,
    });
    return res?.data?.ventureProjectProfileListCommitmentApprovals ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDealStructuresForProjectProfile(): Promise<
  IDealStructure[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListDealStructuresDocument,
    });
    return res?.data?.ventureProjectProfileListDealStructures ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentCurrenciesForProjectProfile(): Promise<
  IInvestmentCurrency[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProjectProfileListInvestmentCurrenciesDocument,
    });
    return res?.data?.ventureProjectProfileListInvestmentCurrencies ?? [];
  } catch (e) {
    throw e;
  }
}

export async function deleteVentureProjectProfile(
  ventureProjectProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureProjectProfileDeleteDocument,
      variables: {
        ventureProjectProfileId,
      },
    });
    return Boolean(res?.data?.ventureProjectProfileDelete);
  } catch (error: any) {
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.ERRORS,
        message: error.message,
        mode: NotificationMode.ERROR,
      })
    );
    throw error;
  }
}

export async function archiveVentureProjectProfile(
  ventureProjectProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureProjectProfileArchiveDocument,
      variables: {
        ventureProjectProfileId,
      },
    });
    return Boolean(res?.data?.ventureProjectProfileArchive);
  } catch (error) {
    throw error;
  }
}

export async function unarchiveVentureProjectProfile(
  ventureProjectProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureProjectProfileUnarchiveDocument,
      variables: {
        ventureProjectProfileId,
      },
    });
    return Boolean(res?.data?.ventureProjectProfileUnarchive);
  } catch (error) {
    throw error;
  }
}

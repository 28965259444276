import {
  InvestorProfileAddTagDocument,
  InvestorIntakeInterviewCreateDocument,
  InvestorNoteCreateDocument,
  InvestorProfileCreateDocument,
  InvestorNoteDeleteDocument,
  InvestorNotesDocumentDeleteDocument,
  InvestorProfileRemoveTagDocument,
  DigitalAssetsConcernListDocument,
  InvestorNotesDocumentDownloadDocument,
  InvestorProfileExportDocument,
  ExposureMotivationListDocument,
  InvestorDocumentDownloadDocument,
  InvestorDocumentUploadDocument,
  CapitalMovementListByInvestorProfileDocument,
  InvestorProfileExportFileTypeDocument,
  InvestorProfileListDocument,
  InvestorIntakeInterviewGetByIdDocument,
  InvestmentCapacityListDocument,
  InvestmentTypeListDocument,
  InvestorProfileGetByIdDocument,
  InvestorProfilesPaginatedDocument,
  SourceOfInvestmentListDocument,
  InvestorNoteUpdateDocument,
  InvestorProfileUpdateDocument,
  InvestorNotesDocumentUploadDocument,
  InvestorNoteVerifyDocument,
  InvestorProfileGetByProductFundAndStatusDocument,
  InvestorProfileListCompanyStructuresDocument,
  InvestorProfileListExistingPortfolioRangesDocument,
  InvestorIntakeInterviewListExistingPortfolioRangesDocument,
  InvestorProfileListPreferredMeetingTimesDocument,
  InvestorProfileListInvestmentStylesDocument,
  InvestorProfileListInvestmentCompanyValuesDocument,
  InvestorProfileListDigitalAssetsKnowledgeDocument,
  InvestorProfileListDigitalAssetsManagerDocument,
  InvestorProfileListDigitalAssetsInterestDocument,
  InvestorIntakeInterviewListInvestmentExecutionsDocument,
  InvestorIntakeInterviewListTransferringExperiencesDocument,
  InvestorIntakeInterviewListTaxIdentificationsDocument,
  InvestorIntakeInterviewListDocumentConfirmationsDocument,
  InvestorIntakeInterviewListWalletTypesDocument,
  InvestorIntakeInterviewListBlockchainWalletTypesDocument,
  InvestorIntakeInterviewListInvestmentExchangeTypesDocument,
  InvestorIntakeInterviewListAssetTypesDocument,
  InvestorProfileListTradingLevelsDocument,
  InvestorProfileArchiveDocument,
  InvestorProfileUnarchiveDocument,
  InvestorProfileDeleteDocument,
} from "api/generated";
import {
  Optional,
  OptionalNullable,
  PartialNullable,
  ResourceEnum,
  ScopeEnum,
  mapInvestorName,
} from "config";

import { ErrorCodes, RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  GetInvestorProfileByIdReturnType,
  ICreateInvestorProfileArgs,
  IGetInvestorProfileArgs,
  IGetInvestorProfilesReturnType,
  IInvestorProfileModel,
  IInvestmentCapacity,
  IInvestmentType,
  ISourceOfInvestment,
  IUpdateInvestorProfileArgs,
  IUploadDocArgs,
  IUploadReturnType,
  IExposureMotivation,
  IDigitalAssetsConcern,
  ICreateInvestorIntakeInterviewArgs,
  IInvestorIntakeInterviewModel,
  InvestorProfilesNameReturnType,
  IInvestorNoteModel,
  ICreateInvestorNote,
  INoteUploadReturnType,
  ICapitalMovementListByInvestorProfileArgs,
  IExportInvestorProfileArgs,
  IUpdateInvestorNoteArgs,
  IUpdateInvestorNoteResponse,
  IInvestorProfilesByProductFundAndStatusVars,
  InvestorProfilesByProductFundAndStatusResponse,
  ICompanyStructureModel,
  IExistingPortfolioRangeModel,
  IPreferredMeetingTimeModel,
  IInvestmentStyleModel,
  IInvestmentCompanyValueModel,
  IDigitalAssetsKnowledgeModel,
  IDigitalAssetsManagerModel,
  IDigitalAssetsInterestModel,
  IInvestmentExecutionModel,
  ITransferringExperienceModel,
  ITaxIdentificationModel,
  IDocumentConfirmationModel,
  IWalletTypeModel,
  IBlockchainWalletTypeModel,
  IInvestmentExchangeTypeModel,
  IAssetTypeModel,
  ITradingLevelModel,
} from "./types";
import { ICapitalMovementByIdReturnType } from "../capital-movement/types";

export async function getInvestmentCapacity(): Promise<IInvestmentCapacity[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentCapacityListDocument,
    });
    return res?.data?.investmentCapacityList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getExposureMotivation(): Promise<IExposureMotivation[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ExposureMotivationListDocument,
    });
    return res?.data?.exposureMotivationList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getDigitalAssetsConcern(): Promise<
  IDigitalAssetsConcern[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: DigitalAssetsConcernListDocument,
    });
    return res?.data?.digitalAssetsConcernList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getInvestmentType(): Promise<IInvestmentType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestmentTypeListDocument,
    });
    return res?.data?.investmentTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getSourceOfInvestment(): Promise<ISourceOfInvestment[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: SourceOfInvestmentListDocument,
    });
    return res?.data?.sourceOfInvestmentList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function createInvestorProfile(
  variables: ICreateInvestorProfileArgs
): Promise<Optional<Pick<IInvestorProfileModel, "id">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileCreateDocument,
      variables,
    });
    return res?.data?.investorProfileCreate;
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfileList(
  variables: IGetInvestorProfileArgs
): Promise<IGetInvestorProfilesReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfilesPaginatedDocument,
      variables,
    });
    return res?.data?.investorProfilesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfileById(
  investorProfileId: number
): Promise<GetInvestorProfileByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileGetByIdDocument,
      variables: {
        investorProfileId,
      },
    });
    if (
      res?.errors &&
      (res?.errors?.[0]?.extensions?.exception as any)?.code ===
        ErrorCodes.INVESTOR_PROFILE_NOT_FOUND
    ) {
      throw res?.errors?.[0];
    }
    if (!res?.data?.investorProfileGetById) {
      return res?.data?.investorProfileGetById;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...rest } = res?.data?.investorProfileGetById;
    return rest;
  } catch (error) {
    throw error;
  }
}

export async function updateInvestorProfile(
  variables: IUpdateInvestorProfileArgs
): Promise<Optional<Pick<IInvestorProfileModel, "id">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileUpdateDocument,
      variables,
    });
    return res?.data?.investorProfileUpdate;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocument(
  variables: IUploadDocArgs
): Promise<Optional<IUploadReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorDocumentUploadDocument,
      variables,
    });
    return res?.data?.investorDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadDocument(
  documentId: number
): Promise<OptionalNullable<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorDocumentDownloadDocument,
      variables: {
        documentId,
      },
    });
    return res?.data?.investorDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function createIntakeInterview(
  variables: ICreateInvestorIntakeInterviewArgs
): Promise<
  Optional<Pick<IInvestorIntakeInterviewModel, "investorIntakeInterviewId">>
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorIntakeInterviewCreateDocument,
      variables,
    });
    return res?.data?.investorIntakeInterviewCreate;
  } catch (error) {
    throw error;
  }
}

export async function getIntakeInterviewById(
  investorIntakeInterviewId: number
): Promise<
  PartialNullable<
    Omit<
      IInvestorIntakeInterviewModel,
      "investorIntakeInterviewId" | "investorProfile"
    >
  >
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewGetByIdDocument,
      variables: {
        investorIntakeInterviewId,
      },
    });
    return res?.data?.investorIntakeInterviewGetById;
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfiles(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<InvestorProfilesNameReturnType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return (res?.data?.investorProfileList ?? []).map(mapInvestorName);
  } catch (error) {
    throw error;
  }
}

export async function createInvestorNote(
  variables: ICreateInvestorNote
): Promise<Optional<PartialNullable<Omit<IInvestorNoteModel, "isLocked">>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNoteCreateDocument,
      variables,
    });
    return res?.data?.investorNoteCreate;
  } catch (error) {
    throw error;
  }
}

export async function uploadInvestorNoteDocument(
  variables: IUploadDocArgs
): Promise<Optional<PartialNullable<INoteUploadReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNotesDocumentUploadDocument,
      variables,
    });
    return res?.data?.investorNotesDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadInvestorNoteDocument(
  documentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNotesDocumentDownloadDocument,
      variables: {
        documentId,
      },
    });
    return res?.data?.investorNotesDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function deleteInvestorNoteDocument(
  documentId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNotesDocumentDeleteDocument,
      variables: {
        documentId,
      },
    });
    return Boolean(res?.data?.investorNotesDocumentDelete);
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementListByInvestorProfile(
  variables?: ICapitalMovementListByInvestorProfileArgs
): Promise<
  PartialNullable<
    Omit<ICapitalMovementByIdReturnType, "createdByName" | "updatedByName">
  >[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementListByInvestorProfileDocument,
      variables,
    });
    return (res?.data?.capitalMovementListByInvestorProfile ?? []).map(
      (item) => ({
        ...item,
        investorProfile: {
          ...item.investorProfile,
          ...mapInvestorName(item.investorProfile),
        },
      })
    );
  } catch (error) {
    throw error;
  }
}

export async function addTagInInvestorProfile(
  investorProfileId: number,
  tagId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileAddTagDocument,
      variables: {
        investorProfileId,
        tagId,
      },
    });
    return Boolean(res?.data?.investorProfileAddTag);
  } catch (error) {
    throw error;
  }
}

export async function deleteTagInInvestorProfile(
  investorProfileId: number,
  tagId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileRemoveTagDocument,
      variables: {
        investorProfileId,
        tagId,
      },
    });
    return Boolean(res?.data?.investorProfileRemoveTag);
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfileExportDocumentsFileTypes(): Promise<
  string[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileExportFileTypeDocument,
    });
    return res?.data?.investorProfileExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportInvestorProfileDocument(
  variables: IExportInvestorProfileArgs
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileExportDocument,
      variables,
    });
    return res?.data?.investorProfileExport;
  } catch (error) {
    throw error;
  }
}

export async function verifyInvestorNote(
  investorNoteId: number
): Promise<Optional<Pick<IInvestorNoteModel, "isLocked">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNoteVerifyDocument,
      variables: {
        investorNoteId,
      },
    });
    return res?.data?.investorNoteVerify;
  } catch (error) {
    throw error;
  }
}

export async function updateInvestorNote(
  variables: IUpdateInvestorNoteArgs
): Promise<Optional<PartialNullable<IUpdateInvestorNoteResponse>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNoteUpdateDocument,
      variables,
    });
    return res?.data?.investorNoteUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteInvestorNote(
  investorNoteId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorNoteDeleteDocument,
      variables: {
        investorNoteId,
      },
    });
    return Boolean(res?.data?.investorNoteDelete);
  } catch (error) {
    throw error;
  }
}

export async function archiveInvestorProfile(
  investorProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileArchiveDocument,
      variables: {
        investorProfileId,
      },
    });
    return Boolean(res?.data?.investorProfileArchive);
  } catch (error) {
    throw error;
  }
}

export async function unarchiveInvestorProfile(
  investorProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileUnarchiveDocument,
      variables: {
        investorProfileId,
      },
    });
    return Boolean(res?.data?.investorProfileUnarchive);
  } catch (error) {
    throw error;
  }
}

export async function deleteInvestorProfile(
  investorProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileDeleteDocument,
      variables: {
        investorProfileId,
      },
    });
    return Boolean(res?.data?.investorProfileDelete);
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfilesByProductFundAndStatus(
  variables: IInvestorProfilesByProductFundAndStatusVars
): Promise<InvestorProfilesByProductFundAndStatusResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileGetByProductFundAndStatusDocument,
      variables,
    });
    return (res?.data?.investorProfileGetByProductFundAndStatus ?? []).map(
      mapInvestorName
    );
  } catch (error) {
    throw error;
  }
}

export async function getCompanyStructuresForInvestorProfile(): Promise<
  ICompanyStructureModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListCompanyStructuresDocument,
    });
    return res?.data?.investorProfileListCompanyStructures ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getExistingPortfolioRangeForInvestorProfile(): Promise<
  IExistingPortfolioRangeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListExistingPortfolioRangesDocument,
    });
    return res?.data?.investorProfileListExistingPortfolioRanges ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getExistingPortfolioRangeForIntakeInterview(): Promise<
  IExistingPortfolioRangeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListExistingPortfolioRangesDocument,
    });
    return res?.data?.investorIntakeInterviewListExistingPortfolioRanges ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getPreferredMeetingTimeForInvestorProfile(): Promise<
  IPreferredMeetingTimeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListPreferredMeetingTimesDocument,
    });
    return res?.data?.investorProfileListPreferredMeetingTimes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentStylesForInvestorProfile(): Promise<
  IInvestmentStyleModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListInvestmentStylesDocument,
    });
    return res?.data?.investorProfileListInvestmentStyles ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentCompanyValuesForInvestorProfile(): Promise<
  IInvestmentCompanyValueModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListInvestmentCompanyValuesDocument,
    });
    return res?.data?.investorProfileListInvestmentCompanyValues ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDigitalAssetsKnowledgeForInvestorProfile(): Promise<
  IDigitalAssetsKnowledgeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListDigitalAssetsKnowledgeDocument,
    });
    return res?.data?.investorProfileListDigitalAssetsKnowledge ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDigitalAssetsManagersForInvestorProfile(): Promise<
  IDigitalAssetsManagerModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListDigitalAssetsManagerDocument,
    });
    return res?.data?.investorProfileListDigitalAssetsManager ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDigitalAssetsInterestsForInvestorProfile(): Promise<
  IDigitalAssetsInterestModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListDigitalAssetsInterestDocument,
    });
    return res?.data?.investorProfileListDigitalAssetsInterest ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentExecutionsForIntakeInterview(): Promise<
  IInvestmentExecutionModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListInvestmentExecutionsDocument,
    });
    return res?.data?.investorIntakeInterviewListInvestmentExecutions ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getTransferringExperiencesForIntakeInterview(): Promise<
  ITransferringExperienceModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListTransferringExperiencesDocument,
    });
    return res?.data?.investorIntakeInterviewListTransferringExperiences ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getTaxIdentificationsForIntakeInterview(): Promise<
  ITaxIdentificationModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListTaxIdentificationsDocument,
    });
    return res?.data?.investorIntakeInterviewListTaxIdentifications ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDocumentConfirmationsForIntakeInterview(): Promise<
  IDocumentConfirmationModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListDocumentConfirmationsDocument,
    });
    return res?.data?.investorIntakeInterviewListDocumentConfirmations ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getWalletTypesForIntakeInterview(): Promise<
  IWalletTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListWalletTypesDocument,
    });
    return res?.data?.investorIntakeInterviewListWalletTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getBlockchainWalletTypesForIntakeInterview(): Promise<
  IBlockchainWalletTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListBlockchainWalletTypesDocument,
    });
    return res?.data?.investorIntakeInterviewListBlockchainWalletTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentExchangeTypesForIntakeInterview(): Promise<
  IInvestmentExchangeTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListInvestmentExchangeTypesDocument,
    });
    return res?.data?.investorIntakeInterviewListInvestmentExchangeTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getAssetTypesForIntakeInterview(): Promise<
  IAssetTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorIntakeInterviewListAssetTypesDocument,
    });
    return res?.data?.investorIntakeInterviewListAssetTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getTradingLevelForInvestorProfile(): Promise<
  ITradingLevelModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileListTradingLevelsDocument,
    });
    return res?.data?.investorProfileListTradingLevels ?? [];
  } catch (e) {
    throw e;
  }
}

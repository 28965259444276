import { getRequestInstance } from "api/services/utils";
import {
  PwmNoteTypeCreateDocument,
  PwmNoteTypeDeleteDocument,
  PwmNoteTypeGetByIdDocument,
  PwmNoteTypeListDocument,
  PwmNoteTypeUpdateDocument,
  PwmNoteTypesPaginatedDocument,
} from "api/generated";
import { RequestTypes } from "api/services/enums";
import { ResourceEnum, ScopeEnum } from "config";

import {
  ICreateNoteTypeVars,
  IGetNoteTypeByIdReturnType,
  IGetNotesListArgs,
  INoteTypeModel,
  INotesListReturnType,
  IUpdateNoteTypeVars,
} from "../../library/note-types/types";

export async function getPwmNoteTypesList(
  variables: IGetNotesListArgs
): Promise<INotesListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmNoteTypesPaginatedDocument,
      variables,
    });
    return res?.data?.pwmNoteTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createPwmNoteType(
  variables: ICreateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmNoteTypeCreateDocument,
      variables,
    });
    return res?.data?.pwmNoteTypeCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updatePwmNoteType(
  variables: IUpdateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmNoteTypeUpdateDocument,
      variables,
    });
    return res?.data?.pwmNoteTypeUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deletePwmNoteType(noteTypeId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmNoteTypeDeleteDocument,
      variables: {
        noteTypeId,
      },
    });
    return Boolean(res?.data?.pwmNoteTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getPwmNoteType(
  noteTypeId: number
): Promise<IGetNoteTypeByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmNoteTypeGetByIdDocument,
      variables: {
        noteTypeId,
      },
    });
    return res?.data?.pwmNoteTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function getPwmNoteTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<INoteTypeModel, "noteTypeId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmNoteTypeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.pwmNoteTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

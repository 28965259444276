import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IHrQuantDistributionHistoryPaginatedResponse,
  getHrQuantDistributionHistoryPaginated,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { HrQuantDistributionModalMode } from "./enums";
import { IHrQuantDistributionModalData } from "./types";

const initialState = {
  hrQuantDistributionData: null as Nullable<
    IHrQuantDistributionHistoryPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: HrQuantDistributionModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrQuantDistributionModalData,
};

export const fetchHrQuantDistributionList = createAsyncThunk(
  "hr/fetchHrQuantDistributionList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getHrQuantDistributionHistoryPaginated(filters);
    return response;
  }
);

const hrQuantDistributionSlice = createSlice({
  name: "hrQuantDistribution",
  initialState,
  reducers: {
    setHrQuantDistributionModalData: (
      state,
      action: PayloadAction<IHrQuantDistributionModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrQuantDistributionModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrQuantDistributionModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHrQuantDistributionList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        hrQuantDistributionData: action.payload
          ?.edges as IHrQuantDistributionHistoryPaginatedResponse["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchHrQuantDistributionList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setHrQuantDistributionModalData,
  closeHrQuantDistributionModal,
  setHrQuantDistributionModalLoading,
} = hrQuantDistributionSlice.actions;
export default hrQuantDistributionSlice.reducer;

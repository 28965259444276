import {
  CapitalMovementSwitchingCreateDocument,
  ProductFundCreateDocument,
  ProductFundDeleteDocument,
  CapitalMovementSwitchingListDocument,
  ProductFundGetByIdDocument,
  ProductFundListDocument,
  ProductFundsPaginatedDocument,
  ProductFundUpdateDocument,
  VentureProductFundListDocument,
  ProductFundGetFundManagerDocument,
  QuantProductFundListDocument,
  ProductFundTypeListDocument,
  ProductFundCapitalTypeListDocument,
} from "api/generated";
import { ICommonListModel } from "api/services/types";
import {
  Nullable,
  Optional,
  PartialNullable,
  ResourceEnum,
  ScopeEnum,
} from "config";
import {
  NotificationDuration,
  NotificationMode,
  showSnackBar,
  store,
} from "store";
import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  CreateFundArgsType,
  ICapitalMovementSwitchingModel,
  ICreateCapitalMovementSwitchingVars,
  IGetProductFundArgs,
  IGetProductFundListResponse,
  IProductFundModel,
  UpdateProductArgs,
} from "./types";

export async function getProductFundList(
  variables?: IGetProductFundArgs
): Promise<IGetProductFundListResponse> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: ProductFundsPaginatedDocument,
    variables,
  });
  return res?.data?.productFundsPaginated;
}

export async function getProductFundTypeList(): Promise<ICommonListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProductFundTypeListDocument,
    });
    return res?.data?.productFundTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getProductFundCapitalTypeList(): Promise<
  ICommonListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProductFundCapitalTypeListDocument,
    });
    return res?.data?.productFundCapitalTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getProductFundListForFilters(): Promise<
  Omit<
    IProductFundModel,
    | "updated"
    | "updatedBy"
    | "updatedByName"
    | "created"
    | "createdBy"
    | "createdByName"
    | "dividendFrequency"
  >[]
> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: ProductFundsPaginatedDocument,
  });
  return res?.data?.productFundsPaginated?.edges?.map((el) => el.node) ?? [];
}

export async function createProductFund(
  variables: CreateFundArgsType
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ProductFundCreateDocument,
      variables,
    });
    return res?.data?.productFundCreate ?? false;
  } catch (error: any) {
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.ERRORS,
        message: error.message,
        mode: NotificationMode.ERROR,
      })
    );
    throw error;
  }
}

export async function getProductFundById(
  productFundId: number
): Promise<PartialNullable<IProductFundModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProductFundGetByIdDocument,
      variables: {
        productFundId,
      },
    });
    return res?.data?.productFundGetById;
  } catch (error) {
    throw error;
  }
}

export async function getProductFundManager(
  productFundId: number
): Promise<Optional<Nullable<number>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProductFundGetFundManagerDocument,
      variables: {
        productFundId,
      },
    });
    return res?.data?.productFundGetFundManager;
  } catch (error) {
    throw error;
  }
}

export async function updateProductFund(
  variables: UpdateProductArgs
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ProductFundUpdateDocument,
      variables,
    });
    return res?.data?.productFundUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteProductFund(
  productFundId: number
): Promise<boolean> {
  const makeRequest = getRequestInstance(RequestTypes.MUTATE);
  const res = await makeRequest({
    mutation: ProductFundDeleteDocument,
    variables: {
      productFundId,
    },
  });
  return Boolean(res?.data?.productFundDelete);
}

export async function getProductFundsName(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<
  PartialNullable<Pick<IProductFundModel, "name" | "id" | "sharePrice">>[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ProductFundListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.productFundList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function createCapitalMovementSwitching(
  variables: ICreateCapitalMovementSwitchingVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CapitalMovementSwitchingCreateDocument,
      variables,
    });
    return Boolean(res?.data?.capitalMovementSwitchingCreate);
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementSwitching(
  productFundId: number
): Promise<ICapitalMovementSwitchingModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementSwitchingListDocument,
      variables: { productFundId },
    });
    return res?.data?.capitalMovementSwitchingList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getVentureProductFunds(): Promise<
  PartialNullable<Pick<IProductFundModel, "name" | "id" | "sharePrice">>[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProductFundListDocument,
    });
    return res?.data?.ventureProductFundList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getQuantProductFunds(): Promise<
  PartialNullable<Pick<IProductFundModel, "name" | "id" | "sharePrice">>[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: QuantProductFundListDocument,
    });
    return res?.data?.quantProductFundList ?? [];
  } catch (error) {
    throw error;
  }
}

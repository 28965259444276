import {
  CapitalMovementTypeEnum,
  DepartmentEnum,
  UserInteractionTypeEnum,
  CustomPalette,
  RoutePaths,
  capitalizeFirstLetter,
  TaskStatusEnum,
} from "config";

export const initialQuillValue = "<p><br></p>";
export const emptyQuillValue = `<p style="margin: 0px;"><br></p>`;
export const MAX_32_BIT_INT = 2147483647;
export const NOT_AVAILABLE_INFO = "N/A";
export const minDate = -86400000000000;
export const maxDate = 86400000000000;

export const customCapitalMovementsTypeNames = {
  [CapitalMovementTypeEnum.ADDON_SUBSCRIPTION]: "Add-on investment",
  [CapitalMovementTypeEnum.DIVIDEND_INVESTMENT_SUBSCRIPTION]:
    "Dividend re-investment",
} as Record<CapitalMovementTypeEnum, string>;

export const amountFilterRangeData = [
  [0, 25000],
  [25000, 50000],
  [50000, 75000],
  [75000, 100000],
  [100000],
];

export const FULL_DATE_FORMAT = "M/d/yyyy";
export const TIME_FORMAT = "h:m:ss aa";
export const TIME_WITHOUT_SECONDS_FORMAT = "h:mm aa";
export const INVESTOR_CATEGORY_CONSTANT = "Investor";
export const LINK_AGENT_CATEGORY_CONSTANT = "Capital Introducer";
export const EMPLOYEE_CATEGORY_CONSTANT = "Employee";
export const SELECT_ALL_SELECT_OPTION = "selectAll";
export const VARVCF_FUND_NAME = "varvcf";
export const VARDFI_FUND_NAME = "vardfi";
export const ADMINISTRATION_DEPARTMENT_CONSTANT = 6;

export const LinkAgentUserRoutes = [
  RoutePaths.LINK_AGENT_MY_INFO,
  RoutePaths.LINK_AGENT_PAYOUT,
  RoutePaths.LINK_AGENT_REPORT,
  RoutePaths.LINK_AGENT_RESOURCES,
];

export const InvestorUserRoutes = [
  RoutePaths.DASHBOARD,
  RoutePaths.INVESTOR_CAPITAL_MOVEMENT,
  RoutePaths.INVESTOR_MY_INFO,
  RoutePaths.INVESTOR_REPORTS,
  RoutePaths.INVESTOR_STATEMENTS,
];

export const userInteractionTypeToSelectiveOptions = Object.values(
  UserInteractionTypeEnum
).map((el, idx) => ({ value: `${idx + 1}`, label: capitalizeFirstLetter(el) }));

export const taskStatusToColorMap: Record<TaskStatusEnum, CustomPalette> = {
  [TaskStatusEnum.BLOCKED]: CustomPalette.red,
  [TaskStatusEnum.COMPLETED]: CustomPalette.light_green,
  [TaskStatusEnum.DECLINED]: CustomPalette.black,
  [TaskStatusEnum.IN_PROGRESS]: CustomPalette.golden,
  [TaskStatusEnum.IN_REVIEW]: CustomPalette.lighter_blue,
  [TaskStatusEnum.NOT_STARTED]: CustomPalette.darker_gray,
};

export const taskStatusToTextMap: Record<TaskStatusEnum, string> = {
  [TaskStatusEnum.BLOCKED]: "Blocked",
  [TaskStatusEnum.COMPLETED]: "Completed",
  [TaskStatusEnum.DECLINED]: "Declined",
  [TaskStatusEnum.IN_PROGRESS]: "In Progress",
  [TaskStatusEnum.IN_REVIEW]: "Review Needed",
  [TaskStatusEnum.NOT_STARTED]: "Not Started",
};

export const taskDepartmentToColorMap: Record<string, CustomPalette> = {
  [DepartmentEnum.VENTURE]: CustomPalette.yellow,
  [DepartmentEnum.QUANTITATIVE]: CustomPalette.purple,
  [DepartmentEnum.CAPITAL_FORMATION]: CustomPalette.red,
  [DepartmentEnum.FINANCE]: CustomPalette.default_green,
  [DepartmentEnum.ADMINISTRATION]: CustomPalette.orange,
  [DepartmentEnum.MANAGEMENT]: CustomPalette.black,
};

export const defaultTaskDepartmentColor = CustomPalette.darker_gray;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IHrQuantPerformanceModel, getHrQuantPerformanceList } from "api";
import { Optional } from "config";
import { store } from "store";

const initialState: {
  data: IHrQuantPerformanceModel[];
  loading: boolean;
  productFundFilter?: number[];
} = {
  data: [],
  loading: false,
};

export const fetchHrQuantPerformanceList = createAsyncThunk(
  "hr/fetchHrQuantPerformanceList",
  async () => {
    const productFundFilter =
      store.getState()?.HrQuantPerformanceReducer?.productFundFilter;
    const response = await getHrQuantPerformanceList(productFundFilter);
    return response;
  }
);

const hrQuantPerformanceSlice = createSlice({
  name: "hrQuantPerformance",
  initialState,
  reducers: {
    setProductFundFilter: (
      state,
      action: PayloadAction<Optional<number[]>>
    ) => {
      const payload = action.payload;
      return {
        ...state,
        productFundFilter: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHrQuantPerformanceList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    });
    builder.addCase(fetchHrQuantPerformanceList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setProductFundFilter } = hrQuantPerformanceSlice.actions;
export default hrQuantPerformanceSlice.reducer;

import { ICommonModalData } from "config";

export enum WesterosPwmExchangeModalMode {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
}

export interface IWesterosPwmExchangeModalData
  extends ICommonModalData<WesterosPwmExchangeModalMode> {
  id?: number;
  loading: boolean;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getPwmDocumentListPaginated, IDocumentListResponse } from "api";
import { Nullable } from "config";
import { DocumentModalMode, RootState } from "store";

import { IWesterosPwmDocumentListModalData } from "./types";

const initialState = {
  documentList: null as Nullable<IDocumentListResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: DocumentModalMode.VIEW,
  } as IWesterosPwmDocumentListModalData,
};

export const fetchWesterosPwmDocumentList = createAsyncThunk(
  "westeros-pwm-document-list/fetchWesterosPwmDocumentList",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getPwmDocumentListPaginated(filters);
    return response;
  }
);

const westerosPwmDocumentListSlice = createSlice({
  name: "westeros-pwm-document-list",
  initialState,
  reducers: {
    setWesterosPwmDocumentListModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeWesterosPwmDocumentListModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosPwmDocumentList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        documentList: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchWesterosPwmDocumentList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setWesterosPwmDocumentListModalData,
  closeWesterosPwmDocumentListModal,
} = westerosPwmDocumentListSlice.actions;
export default westerosPwmDocumentListSlice.reducer;

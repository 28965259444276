import { ICommonListModel } from "api/services/types";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrCapitalFormationDistributionHistoryGetByEmployeeDocument,
  HrCapitalFormationDistributionHistoryPaginatedDocument,
  HrCapitalFormationDistributionHistoryCreateDocument,
  HrCapitalFormationDistributionHistoryUpdateDocument,
  HrCapitalFormationDistributionHistoryDeleteDocument,
  HrCapitalFormationDistributionCurrencyListDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IHrCapitalFormationDistributionHistoryModel,
  IHrCapitalFormationDistributionHistoryCreateVars,
  IHrCapitalFormationDistributionHistoryPaginatedResponse,
  IHrCapitalFormationDistributionHistoryPaginatedVars,
  IHrCapitalFormationDistributionHistoryUpdateVars,
} from "./types";

export async function getHrCapitalFormationDistributionCurrencyList(): Promise<
  ICommonListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrCapitalFormationDistributionCurrencyListDocument,
    });
    return res?.data?.hrCapitalFormationDistributionCurrencyList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrCapitalFormationDistributionHistory(
  employeeId: number
): Promise<Optional<IHrCapitalFormationDistributionHistoryModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrCapitalFormationDistributionHistoryGetByEmployeeDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrCapitalFormationDistributionHistoryGetByEmployee;
  } catch (error) {
    throw error;
  }
}

export async function getHrCapitalFormationDistributionHistoryPaginated(
  variables: IHrCapitalFormationDistributionHistoryPaginatedVars
): Promise<Optional<IHrCapitalFormationDistributionHistoryPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrCapitalFormationDistributionHistoryPaginatedDocument,
      variables,
    });
    return res?.data?.hrCapitalFormationDistributionHistoryListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createHrCapitalFormationDistributionHistory(
  variables: IHrCapitalFormationDistributionHistoryCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrCapitalFormationDistributionHistoryCreateDocument,
      variables,
    });
    return Boolean(res?.data?.hrCapitalFormationDistributionHistoryCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateHrCapitalFormationDistributionHistory(
  variables: IHrCapitalFormationDistributionHistoryUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrCapitalFormationDistributionHistoryUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.hrCapitalFormationDistributionHistoryUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteHrCapitalFormationDistributionHistory(
  id: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrCapitalFormationDistributionHistoryDeleteDocument,
      variables: { id },
    });
    return Boolean(res?.data?.hrCapitalFormationDistributionHistoryDelete);
  } catch (error) {
    throw error;
  }
}

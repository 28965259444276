import { format } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

import { AggregationFilterTypeKeys, ConditionFiltersTypeKeys } from "api";
import { FiltersType, ISelectOption } from "components";
import {
  DiscordIcon,
  Entries,
  FacebookIcon,
  FULL_DATE_FORMAT,
  GitIcon,
  InvestorTypeEnum,
  isNumberWeak,
  LineIcon,
  LinkedinIcon,
  MAX_32_BIT_INT,
  NOT_AVAILABLE_INFO,
  Nullable,
  Optional,
  QUERY_STRINGS,
  SELECT_ALL_SELECT_OPTION,
  SkypeIcon,
  SlackIcon,
  SocialNetworks,
  TelegramIcon,
  TIME_FORMAT,
  TIME_WITHOUT_SECONDS_FORMAT,
  TwitterIcon,
  UNEXPECTED_UNICODES_REGEX,
  ViberIcon,
  WhatsappIcon,
} from "config";
import { InputRefType } from "hooks/types";

export function transformFiltersForPayload(filters: FiltersType) {
  return Object.entries(filters).reduce(
    (filterAcc, [filterKey, filterValue]) => {
      const isArray = Array.isArray(filterValue.value);
      let payloadValue: any = filterValue.value;
      let filtersPayloadKeysType:
        | ConditionFiltersTypeKeys
        | AggregationFilterTypeKeys = isArray
        ? ConditionFiltersTypeKeys.IN
        : ConditionFiltersTypeKeys.EQ;
      if (filterValue?.isInput) {
        filtersPayloadKeysType = ConditionFiltersTypeKeys.CONTAINS;
      }
      if (filterValue?.isRange) {
        filtersPayloadKeysType = ConditionFiltersTypeKeys.BETWEEN;
        payloadValue = {
          from: +(filterValue.value as string).split(
            QUERY_STRINGS.SEPARATOR
          )[0],
          to:
            +(filterValue.value as string).split(QUERY_STRINGS.SEPARATOR)[1] ||
            MAX_32_BIT_INT,
        };
        if (filterValue?.isAggregation) {
          filtersPayloadKeysType = AggregationFilterTypeKeys.COUNT;
          payloadValue = {
            [ConditionFiltersTypeKeys.BETWEEN]: payloadValue,
          };
        }
      }

      return {
        ...filterAcc,
        [filterKey]: {
          [filtersPayloadKeysType]: payloadValue,
        },
      };
    },
    {}
  );
}

export function createBasicFilters(
  params: Record<string, string>,
  dynamicKeyForSearch = "name"
) {
  return Object.entries(params).reduce((acc, [key, value]) => {
    switch (key) {
      case QUERY_STRINGS.FILTERS: {
        const filtersValue: FiltersType = JSON.parse(value);
        return {
          ...acc,
          filters: {
            ...acc["filters"],
            ...transformFiltersForPayload(filtersValue),
          },
        };
      }
      case QUERY_STRINGS.SEARCH: {
        return {
          ...acc,
          filters: {
            ...acc["filters"],
            [dynamicKeyForSearch]: {
              [ConditionFiltersTypeKeys.CONTAINS]: value,
            },
          },
        };
      }
      case QUERY_STRINGS.LIMIT_PER_PAGE: {
        return {
          ...acc,
          paging: {
            ...acc.paging,
            limit: +value,
          },
        };
      }
      case QUERY_STRINGS.PAGINATION: {
        return {
          ...acc,
          paging: {
            ...acc.paging,
            page: +value - 1,
          },
        };
      }
      case QUERY_STRINGS.SORT: {
        return {
          ...acc,
          sorting: {
            orderBy: {
              field: value.split(QUERY_STRINGS.SEPARATOR)[0],
              direction: value.split(QUERY_STRINGS.SEPARATOR)[1].toUpperCase(),
            },
          },
        };
      }
      default: {
        return {
          ...acc,
        };
      }
    }
  }, {} as any);
}

export function numberWithoutCommas(n: string | number): number {
  if (typeof n === "string") {
    return parseFloat(n.replace(/,/g, ""));
  }

  return n;
}

export function floatNumberWithCommas(
  n: number | string,
  pretify = false,
  roundTo?: number
): string {
  if (n === 0) {
    return n.toFixed(roundTo || 0);
  }
  if (!n) {
    n = "";
  }
  if (roundTo !== undefined && typeof n === "number") {
    n = parseFloat(n.toFixed(roundTo));
  }
  const [fixedPart, decimalPart] = n.toString().split(".");
  if (typeof fixedPart === "string") {
    if (!isNumberWeak(fixedPart)) {
      return fixedPart;
    }
  }
  let numberWithoutCommasValue = `${numberWithoutCommas(
    fixedPart
  ).toLocaleString()}${decimalPart !== undefined ? `.${decimalPart}` : ""}`;
  if (decimalPart && decimalPart.includes("e-")) {
    numberWithoutCommasValue = floatNumberWithCommas(
      Number(numberWithoutCommas(n.toString())).toFixed(10)
    );
  } else if (decimalPart && decimalPart.includes("e+")) {
    numberWithoutCommasValue = floatNumberWithCommas(
      Number(numberWithoutCommas(n.toString())).toFixed(2)
    );
  } else if (
    (!decimalPart || decimalPart.length <= (roundTo ?? 2) - 1) &&
    pretify
  ) {
    numberWithoutCommasValue = floatNumberWithCommas(
      Number(numberWithoutCommas(n.toString())).toFixed(roundTo ?? 2)
    );
  }

  return numberWithoutCommasValue;
}

export function numberWithCommas(n: number | string): string {
  if (typeof n === "string") {
    if (!isNumberWeak(n)) {
      return n;
    }
  }
  return `${numberWithoutCommas(`${n}`).toLocaleString()}`;
}

export function capitalizeFirstLetter(str: string): string {
  const normalized = str.replace(/_/g, " ");
  const capitalized = normalized
    .toLowerCase()
    .replace(/^./, str[0].toUpperCase());

  return capitalized;
}

export function removeUnexpectedUnicodes(str: string): string {
  return str.replace(UNEXPECTED_UNICODES_REGEX, "");
}

export const SocialNetworkIconsMap: Record<SocialNetworks, () => JSX.Element> =
  {
    [SocialNetworks.DISCORD]: () => <DiscordIcon />,
    [SocialNetworks.LINE]: () => <LineIcon />,
    [SocialNetworks.TELEGRAM]: () => <TelegramIcon />,
    [SocialNetworks.TWITTER]: () => <TwitterIcon />,
    [SocialNetworks.WHATSAPP]: () => <WhatsappIcon />,
    [SocialNetworks.SLACK]: () => <SlackIcon />,
    [SocialNetworks.LINKEDIN]: () => <LinkedinIcon />,
    [SocialNetworks.SKYPE]: () => <SkypeIcon />,
    [SocialNetworks.VIBER]: () => <ViberIcon />,
    [SocialNetworks.FACEBOOK]: () => <FacebookIcon />,
    [SocialNetworks.GIT]: () => <GitIcon />,
  };

export function makeEnumToSelectiveOption<T extends string>(
  enums: {
    [e in T]: string;
  },
  capitalize = true,
  customOption: Record<string, string> = {}
) {
  return Object.values<string>(enums).map((el) => {
    if (customOption[el]) {
      return {
        value: el,
        label: customOption[el],
      };
    }
    return {
      value: el,
      label: capitalize ? capitalizeFirstLetter(el) : el,
    };
  });
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function generateColors(initialColor: string, count: number) {
  const colorArr = [];
  colorArr.push(initialColor);
  let magnitude = 20;
  if (count < 6) magnitude = 50;
  else if (count < 11) magnitude = 20;
  else if (count < 16) magnitude = 15;
  else if (count < 21) magnitude = 10;
  else magnitude = 5;

  while (count > 1) {
    initialColor = newShade(initialColor, magnitude);
    colorArr.push(initialColor);
    count--;
  }

  return colorArr;
}

function newShade(hexColor: string, magnitude: number) {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
}

export const getTimezoneOffsetInHours = () =>
  new Date().getTimezoneOffset() / 60;

export function formatDateInUTC(date: Date | string) {
  const initialDate = new Date(date);
  const dateInUTC = new Date(
    initialDate.getUTCFullYear(),
    initialDate.getUTCMonth(),
    initialDate.getUTCDate(),
    initialDate.getUTCHours(),
    initialDate.getUTCMinutes(),
    initialDate.getUTCSeconds(),
    initialDate.getUTCMilliseconds()
  );
  return dateInUTC;
}

export function convertLocalTimeToUTC(date: Date | string) {
  const initialDate = new Date(date);
  const dateInUTC = new Date(
    Date.UTC(
      initialDate.getFullYear(),
      initialDate.getMonth(),
      initialDate.getDate(),
      initialDate.getHours(),
      initialDate.getMinutes(),
      initialDate.getSeconds(),
      initialDate.getMilliseconds()
    )
  );
  return dateInUTC;
}

export const fullDateAndTimeFormatInUTC = (date: Date | string) =>
  format(formatDateInUTC(date), `${FULL_DATE_FORMAT} ${TIME_FORMAT}`);

export const fullDateAndTimeWithoutSecondsFormatInUTC = (date: Date | string) =>
  format(
    formatDateInUTC(date),
    `${FULL_DATE_FORMAT} ${TIME_WITHOUT_SECONDS_FORMAT}`
  );

export const fullDateFormatInUTC = (date: Date | string) =>
  format(formatDateInUTC(date), FULL_DATE_FORMAT);

export function camelize<T extends string>(str: T) {
  return str
    .replace(/\s(.)/g, (v) => v.toUpperCase())
    .replace(/\s/g, "")
    .replace(/^(.)/, (v) => v.toLowerCase());
}

export function separatePhoneNumberFromCode(phoneNumber: string): string {
  return phoneNumber
    .slice(phoneNumber.indexOf(")") + 1)
    .trim()
    .replace(/-|\s/g, "");
}

export const typedObjectKeys = <T extends Record<string, any>>(obj: T) =>
  Object.keys(obj) as (keyof T)[];

export function toggleHideDomElement(element: Element, delay = 1000) {
  element.classList.toggle("hide");
  setTimeout(() => {
    element.classList.toggle("hide");
  }, delay);
}

export function proceedNotAvailableData<T>(
  item: T,
  func?: (item: NonNullable<T>) => string
): string {
  if ([undefined, null].includes(item as any)) {
    return NOT_AVAILABLE_INFO;
  }

  return func ? func(item as NonNullable<T>) : (item as any).toString();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export function transformFetchedDataIntoSelectiveOptions<
  T extends Record<string, any>
>(
  data: T[],
  keyOfId: keyof T,
  name: ((data: T) => string) | keyof T = "name",
  includeSelectAll?: boolean,
  selectAllLabel?: string,
  additionalPayloadFunc?: (data: T) => any
): ISelectOption[] {
  if (!Array.isArray(data) || !data.length) {
    return [];
  }
  const baseData: ISelectOption[] = includeSelectAll
    ? [
        {
          value: SELECT_ALL_SELECT_OPTION,
          label: selectAllLabel ?? "Select all",
        },
      ]
    : [];
  const selectsData = data.reduce((acc, el) => {
    acc.push({
      label: typeof name === "function" ? name(el) : el[name].toString(),
      value: el[keyOfId].toString(),
      additionalPayload: additionalPayloadFunc
        ? additionalPayloadFunc(el)
        : undefined,
    });
    return acc;
  }, baseData);
  return selectsData;
}

export const typedObjectEntries = <T extends Record<any, any>>(
  object: T
): Entries<T> => Object.entries(object) as Entries<T>;

export function removeLastBrackets(str: string) {
  const firstPart = str.slice(0, str.lastIndexOf("(") - 1);
  const result =
    firstPart + str.slice(str.lastIndexOf(")") + (Boolean(firstPart) ? 1 : 2));
  return result;
}

export const scrollErrorIntoView = (
  errors: Record<string, string>,
  refs: Optional<Nullable<InputRefType<string>>>
) => {
  if (refs) {
    const keys = Object.keys(errors);
    const foundKey = keys.find((el) => Boolean(refs?.[el]));
    if (foundKey) {
      refs[foundKey]?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
};

export const timezoneEntityToLabel = (name: string) => {
  const [standard, symbol, number] = name
    .replace(/MINUS/, "-")
    .replace(/PLUS/, "+")
    .split("_");
  return `${standard}${symbol && number ? `${symbol}${number}` : ""}`;
};

export const getSearchParamsObj = (searchParams: URLSearchParams) => {
  if (!searchParams || typeof searchParams[Symbol.iterator] !== "function") {
    return {};
  }

  const searchParamsObj: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    searchParamsObj[key] = value;
  });

  return searchParamsObj;
};

export function getMaxDate(): Date {
  const maxDate = 86400000000000;

  return new Date(maxDate);
}

export function getMinDate(): Date {
  return new Date(0);
}

export async function exportDomElementAsPDF(
  element: HTMLDivElement,
  pdfTitle: string
) {
  const rect = element.getBoundingClientRect();
  const width = rect.width / 2;
  const height = rect.height / 2;
  const pdfWidth = width + 100;
  const pdfHeight = height + 200;
  const x = 40;
  const y = 80;

  const canvas = await html2canvas(element);
  const pdf = new jsPDF(pdfHeight < pdfWidth ? "l" : "p", "px", [
    pdfHeight,
    pdfWidth,
  ]);
  pdf.setFontSize(9);
  pdf.text(pdfTitle, pdfWidth / 2 - pdfTitle.length * 1.5, 40);
  pdf.addImage(canvas.toDataURL("image/png"), "PNG", x, y, width, height);
  pdf.save(`${pdfTitle}.pdf`);
}

export function mapInvestorName<T>(item: {
  id?: number;
  investorProfileId?: number;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  type: number;
  corporationName?: Nullable<string>;
  fileNumber?: T;
}): {
  id: number;
  fileNumber: T;
  fullName: string;
} {
  return {
    id: item.id || item.investorProfileId || 0,
    fileNumber: item.fileNumber as T,
    fullName:
      item.type === InvestorTypeEnum.INDIVIDUAL
        ? `${item.firstName} ${item.lastName}`
        : `${item.corporationName}`,
  };
}

export function nFormatter(num: number, digits = 0) {
  const isNegative = Math.abs(num) !== num;
  if (isNegative) num = Math.abs(num);
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? `${isNegative ? "-" : ""}${
        (num / item.value).toFixed(digits).replace(regex, "$1") + item.symbol
      }`
    : "0";
}

export function getAge(birthday: Date) {
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
}

export function getDifferenceBetweenDates(
  startDate: Date,
  endDate: Date = new Date()
): string {
  const end = moment(endDate);
  const start = moment(startDate);

  const years = end.diff(start, "year");
  start.add(years, "years");

  const months = end.diff(start, "months");
  start.add(months, "months");

  const days = end.diff(start, "days");

  return `${years ? years + " year(s) " : ""}${
    months ? months + " month(s) " : ""
  }${days ? days + " day(s)" : ""}`;
}

import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import { ICommonListModel } from "api/services/types";
import {
  HrVentureDistributionHistoryGetByEmployeeDocument,
  HrVentureDistributionHistoryPaginatedDocument,
  HrVentureDistributionHistoryCreateDocument,
  HrVentureDistributionHistoryUpdateDocument,
  HrVentureDistributionHistoryDeleteDocument,
  HrVentureDistributionCurrencyListDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IHrVentureDistributionHistoryModel,
  IHrVentureDistributionHistoryCreateVars,
  IHrVentureDistributionHistoryPaginatedResponse,
  IHrVentureDistributionHistoryPaginatedVars,
  IHrVentureDistributionHistoryUpdateVars,
} from "./types";

export async function getHrVentureDistributionCurrencyList(): Promise<
  ICommonListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVentureDistributionCurrencyListDocument,
    });
    return res?.data?.hrVentureDistributionCurrencyList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrVentureDistributionHistory(
  employeeId: number
): Promise<Optional<IHrVentureDistributionHistoryModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVentureDistributionHistoryGetByEmployeeDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrVentureDistributionHistoryGetByEmployee;
  } catch (error) {
    throw error;
  }
}

export async function getHrVentureDistributionHistoryPaginated(
  variables: IHrVentureDistributionHistoryPaginatedVars
): Promise<Optional<IHrVentureDistributionHistoryPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVentureDistributionHistoryPaginatedDocument,
      variables,
    });
    return res?.data?.hrVentureDistributionHistoryListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createHrVentureDistributionHistory(
  variables: IHrVentureDistributionHistoryCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVentureDistributionHistoryCreateDocument,
      variables,
    });
    return Boolean(res?.data?.hrVentureDistributionHistoryCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateHrVentureDistributionHistory(
  variables: IHrVentureDistributionHistoryUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVentureDistributionHistoryUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.hrVentureDistributionHistoryUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteHrVentureDistributionHistory(
  id: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVentureDistributionHistoryDeleteDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.hrVentureDistributionHistoryDelete);
  } catch (error) {
    throw error;
  }
}

import {
  VentureBlockchainCreateDocument,
  VentureBlockchainDeleteDocument,
  VentureBlockchainGetByIdDocument,
  VentureBlockchainListDocument,
  VentureBlockchainPaginatedDocument,
  VentureBlockchainUpdateDocument,
} from "api/generated";
import { PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IVentureBlockchainCreateVars,
  IVentureBlockchainListVars,
  IVentureBlockchainModel,
  IVentureBlockchainPaginatedResponse,
  IVentureBlockchainPaginatedVars,
  IVentureBlockchainUpdateVars,
  VentureBlockchainListResponse,
} from "./types";

export async function getVentureBlockchainPaginated(
  variables: IVentureBlockchainPaginatedVars
): Promise<IVentureBlockchainPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureBlockchainPaginatedDocument,
      variables,
    });
    return res?.data?.ventureBlockchainPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureBlockchain(
  variables: IVentureBlockchainCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureBlockchainCreateDocument,
      variables,
    });
    return res?.data?.ventureBlockchainCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureBlockchain(
  variables: IVentureBlockchainUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureBlockchainUpdateDocument,
      variables,
    });
    return res?.data?.ventureBlockchainUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function getVentureBlockchain(
  ventureBlockchainId: number
): Promise<PartialNullable<IVentureBlockchainModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureBlockchainGetByIdDocument,
      variables: {
        ventureBlockchainId,
      },
    });
    return res?.data?.ventureBlockchainGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureBlockchain(
  ventureBlockchainId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureBlockchainDeleteDocument,
      variables: {
        ventureBlockchainId,
      },
    });
    return Boolean(res?.data?.ventureBlockchainDelete);
  } catch (error) {
    throw error;
  }
}

export async function getVentureBlockchainList(
  variables: IVentureBlockchainListVars
): Promise<VentureBlockchainListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureBlockchainListDocument,
      variables,
    });
    return res?.data?.ventureBlockchainList;
  } catch (error) {
    throw error;
  }
}

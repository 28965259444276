import {
  TagCreateDocument,
  TagDeleteDocument,
  TagGetByIdDocument,
  TagListDocument,
  TagsPaginatedDocument,
  TagUpdateDocument,
} from "api/generated";
import { ResourceEnum, ScopeEnum } from "config";
import {
  NotificationDuration,
  NotificationMode,
  showSnackBar,
  store,
} from "store";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";

import { IGetTagArgs, ITagListReturnType, ITagModel } from "../types";

export async function getTagsList(
  variables?: IGetTagArgs
): Promise<ITagListReturnType> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: TagsPaginatedDocument,
    variables,
  });
  return res?.data?.tagsPaginated;
}

export async function getTagsListForFilters(): Promise<
  Omit<ITagModel, "updated" | "updatedBy" | "updatedByName" | "investorCount">[]
> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: TagsPaginatedDocument,
  });
  return res?.data?.tagsPaginated?.edges?.map((el) => el.node) ?? [];
}

export async function getTagsById(tagId: number): Promise<ITagModel> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: TagGetByIdDocument,
    variables: {
      tagId,
    },
  });
  return res?.data?.tagGetById;
}

export async function createTag(
  name: string,
  description: string
): Promise<boolean> {
  const makeRequest = getRequestInstance(RequestTypes.MUTATE);
  try {
    const res = await makeRequest({
      mutation: TagCreateDocument,
      variables: {
        name,
        description,
      },
    });
    return res?.data?.tagCreate ?? false;
  } catch (error: any) {
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.ERRORS,
        message: error.message,
        mode: NotificationMode.ERROR,
      })
    );
    throw error;
  }
}

export async function updateTag(
  tagId: number,
  name: string,
  description: string
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TagUpdateDocument,
      variables: {
        tagId,
        name,
        description,
      },
    });

    return res?.data?.tagUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteTag(tagId: number): Promise<void> {
  const makeRequest = getRequestInstance(RequestTypes.MUTATE);
  await makeRequest({
    mutation: TagDeleteDocument,
    variables: {
      tagId,
    },
  });
}

export async function getTagsName(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<ITagModel, "name" | "tagId">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TagListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.tagList ?? [];
  } catch (error) {
    throw error;
  }
}

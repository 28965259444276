import {
  VentureTagCreateDocument,
  VentureTagDeleteDocument,
  VentureTagGetByIdDocument,
  VentureTagListDocument,
  VentureTagUpdateDocument,
  VentureTagsPaginatedDocument,
} from "api/generated";
import { ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../../enums";
import { IGetTagArgs, ITagListReturnType, ITagModel } from "../types";
import { getRequestInstance } from "../../../utils";

export async function getVentureTagsList(
  variables: IGetTagArgs
): Promise<ITagListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureTagsPaginatedDocument,
      variables,
    });
    return res?.data?.ventureTagsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureTag(
  name: string,
  description: string
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureTagCreateDocument,
      variables: {
        name,
        description,
      },
    });
    return res?.data?.ventureTagCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureTag(
  tagId: number,
  name: string,
  description: string
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureTagUpdateDocument,
      variables: {
        tagId,
        name,
        description,
      },
    });
    return res?.data?.ventureTagUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function getVentureTag(tagId: number): Promise<ITagModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureTagGetByIdDocument,
      variables: {
        tagId,
      },
    });
    return res?.data?.ventureTagGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureTag(tagId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureTagDeleteDocument,
      variables: {
        tagId,
      },
    });
    return Boolean(res?.data?.ventureTagDelete);
  } catch (error) {
    throw error;
  }
}

export async function getVentureTags(
  scope: ScopeEnum,
  resource: ResourceEnum
): Promise<Pick<ITagModel, "tagId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureTagListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.ventureTagList ?? [];
  } catch (error) {
    throw error;
  }
}

import {
  VentureOriginationSourceCreateDocument,
  VentureOriginationSourceDeleteDocument,
  VentureOriginationSourceGetByIdDocument,
  VentureOriginationSourceListDocument,
  VentureOriginationSourceUpdateDocument,
  VentureOriginationSourcesPaginatedDocument,
} from "api/generated";
import { PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IOriginationSourceCreateVars,
  IOriginationSourceModel,
  IOriginationSourceUpdateVars,
  IOriginationSourceListVars,
  IOriginationSourcesPaginatedResponse,
  IOriginationSourcesPaginatedVars,
  OriginationSourceListResponse,
} from "./types";

export async function getOriginationSourcesPaginated(
  variables: IOriginationSourcesPaginatedVars
): Promise<IOriginationSourcesPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureOriginationSourcesPaginatedDocument,
      variables,
    });
    return res?.data?.ventureOriginationSourcesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createOriginationSource(
  variables: IOriginationSourceCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureOriginationSourceCreateDocument,
      variables,
    });
    return res?.data?.ventureOriginationSourceCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateOriginationSource(
  variables: IOriginationSourceUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureOriginationSourceUpdateDocument,
      variables,
    });
    return res?.data?.ventureOriginationSourceUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function getOriginationSource(
  ventureOriginationSourceId: number
): Promise<PartialNullable<IOriginationSourceModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureOriginationSourceGetByIdDocument,
      variables: {
        ventureOriginationSourceId,
      },
    });
    return res?.data?.ventureOriginationSourceGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteOriginationSource(
  ventureOriginationSourceId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureOriginationSourceDeleteDocument,
      variables: {
        ventureOriginationSourceId,
      },
    });
    return Boolean(res?.data?.ventureOriginationSourceDelete);
  } catch (error) {
    throw error;
  }
}

export async function getOriginationSources(
  variables: IOriginationSourceListVars
): Promise<OriginationSourceListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureOriginationSourceListDocument,
      variables,
    });
    return res?.data?.ventureOriginationSourceList;
  } catch (error) {
    throw error;
  }
}

import { ISelectOption } from "components";
import {
  Nullable,
  Optional,
  STORAGE_CONSTANTS,
  SocialNetworks,
  NOT_AVAILABLE_INFO,
} from "config";

import { errorMessages } from "./constants";
import {
  COMMAS_DOTS_REGEX,
  COMMAS_REGEX,
  DISCORD_USERNAME_REGEX,
  EMAIL_REGEX,
  HYPERLINK_REGEX,
  LETTERS_NUMBERS_ONLY_REGEX,
  PHONE_NUMBER_REGEX,
  TELEGRAM_LINK_REGEX,
  USERNAME_REGEX,
} from "./patterns";

export function isRequired(
  value: Optional<Nullable<string | string[]>>
): boolean {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return (
    value !== null &&
    value !== undefined &&
    (value.trim ? value.trim().length > 0 : true)
  );
}

export function isEmail(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export function isPhoneNumber(phoneNumber: string): boolean {
  return PHONE_NUMBER_REGEX.test(phoneNumber);
}

export function isNumber(number: string): boolean {
  const parsed = number.replace(COMMAS_DOTS_REGEX, "");
  return !parsed && number !== parsed ? false : /^[0-9]*$/.test(parsed);
}

export function isInteger(number: string): boolean {
  return Number.isInteger(Number(number));
}

export function isNumberWeak(number: string): boolean {
  const parsed = parseFloat(number.replace(COMMAS_REGEX, ""));
  return !Number.isNaN(parsed);
}

export function isUsername(string: string): boolean {
  return USERNAME_REGEX.test(string);
}

export function isAlphaNumeric(string: string): boolean {
  return LETTERS_NUMBERS_ONLY_REGEX.test(string);
}

export function isDiscordUsername(string: string): boolean {
  return DISCORD_USERNAME_REGEX.test(string);
}

export function isHyperLink(link: string): boolean {
  return HYPERLINK_REGEX.test(link);
}

export function isTelegramLink(link: string): boolean {
  return TELEGRAM_LINK_REGEX.test(link);
}

export function isSlackId(id: string): boolean {
  return LETTERS_NUMBERS_ONLY_REGEX.test(id);
}

export function isEnvironment(env: string): boolean {
  const split = env.split(",");
  return split.length === 2;
}

export function isCountryExist(name: string): boolean {
  const split = name.split(",");
  if (!split[1]) return false;
  const countries = localStorage.getItem(STORAGE_CONSTANTS.COUNTRIES);
  if (countries) {
    const parsedCountries: ISelectOption[] = JSON.parse(countries);
    const found = parsedCountries.find((el) => {
      return el.label.toLowerCase() === split[1].toLowerCase().trim();
    });

    return Boolean(found);
  }
  return false;
}

export function validateSocialNetworksValues(
  el: SocialNetworks,
  value: string
) {
  if (value && value !== NOT_AVAILABLE_INFO) {
    if (el === SocialNetworks.LINKEDIN) {
      return (
        isHyperLink(value) || {
          [el]: errorMessages.link,
        }
      );
    }
    if (el === SocialNetworks.LINE) {
      return (
        [isUsername, isPhoneNumber].some((func) => func(value)) || {
          [el]: errorMessages.line,
        }
      );
    }
    if (el === SocialNetworks.TWITTER) {
      return (
        isUsername(value) || {
          [el]: errorMessages.username,
        }
      );
    }
    if (el === SocialNetworks.DISCORD) {
      return (
        isRequired(value) || {
          [el]: errorMessages.discord,
        }
      );
    }
    if (el === SocialNetworks.TELEGRAM) {
      return (
        [isUsername, isTelegramLink].some((func) => func(value)) || {
          [el]: errorMessages.telegram,
        }
      );
    }
    if (el === SocialNetworks.WHATSAPP) {
      return (
        isPhoneNumber(value) || {
          [el]: errorMessages.phoneNumber,
        }
      );
    }
    if (el === SocialNetworks.SLACK) {
      return (
        isSlackId(value) || {
          [el]: errorMessages.slack,
        }
      );
    }
  }
}

import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  PwmProfileGetByIdDocument,
  PwmProfileListDocument,
  PwmProfileCreateDocument,
  PwmProfileUpdateDocument,
  PwmProfileListPaginatedDocument,
  PwmInvestorListDocument,
  PwmProfileGetInvestorDocument,
  PwmProfileDeleteDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IPwmProfileModel,
  IPwmProfileCreateVars,
  IPwmProfilePaginatedResponse,
  IPwmProfilePaginatedVars,
  IPwmProfileUpdateVars,
  IPwmProfileListModel,
  IPwmInvestorListModel,
  IInvestorPwmProfileModel,
} from "./types";

export async function getInvestorsAvailableList(): Promise<
  IPwmInvestorListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmInvestorListDocument,
    });
    return res?.data?.pwmInvestorList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfile(
  investorId: number
): Promise<Optional<IPwmProfileModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileGetByIdDocument,
      variables: {
        investorId: investorId,
      },
    });
    return res?.data?.pwmProfileGetById;
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfileInvestor(
  investorId: number
): Promise<Optional<IInvestorPwmProfileModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileGetInvestorDocument,
      variables: {
        investorId: investorId,
      },
    });
    return res?.data?.pwmProfileGetInvestor;
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfilePaginated(
  variables: IPwmProfilePaginatedVars
): Promise<Optional<IPwmProfilePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileListPaginatedDocument,
      variables,
    });
    return res?.data?.pwmProfileListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfileList(): Promise<IPwmProfileListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileListDocument,
    });
    return res?.data?.pwmProfileList || [];
  } catch (error) {
    throw error;
  }
}

export async function createPwmProfile(
  variables: IPwmProfileCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileCreateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileCreate);
  } catch (error) {
    throw error;
  }
}

export async function updatePwmProfile(
  variables: IPwmProfileUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deletePwmProfile(investorId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileDeleteDocument,
      variables: {
        investorId,
      },
    });
    return Boolean(res?.data?.pwmProfileDelete);
  } catch (error) {
    throw error;
  }
}

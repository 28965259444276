import { ICommonModalData } from "config";

export enum WesterosPwmStrategyModalMode {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
}

export interface IWesterosPwmStrategyModalData
  extends ICommonModalData<WesterosPwmStrategyModalMode> {
  id?: number;
  loading: boolean;
}

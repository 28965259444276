import { useCallback } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useIdToken from "hooks/useIdToken.hook";
import {
  INVESTOR_CATEGORY_CONSTANT,
  IRoute,
  InvestorUserRoutes,
  LinkAgentUserRoutes,
  RoutePaths,
  ScopeEnum,
  LINK_AGENT_CATEGORY_CONSTANT,
} from "config";
import {
  routeToResourceMap,
  routeToSpecificScopeMap,
} from "components/access-control/constants";
import { checkPermissions } from "components/access-control/utils";

import { IProtectedRoute } from "./types";

const ProtectedRoute: React.FC<IProtectedRoute> = ({
  route,
  isAllowed,
  parentRoute,
  redirectPath = "/sign-in",
}: IProtectedRoute) => {
  const location = useLocation();
  const idToken = useIdToken();

  const allowRouteByResource = useCallback(
    (el: IRoute): boolean => {
      if (el.path === RoutePaths.HOME) return true;
      if (el.path.endsWith(":id")) return true;
      if (idToken?.category === LINK_AGENT_CATEGORY_CONSTANT) {
        if (LinkAgentUserRoutes.includes(el.path)) return true;
        return false;
      }
      if (LinkAgentUserRoutes.includes(el.path)) return false;
      if (idToken?.category === INVESTOR_CATEGORY_CONSTANT) {
        if (InvestorUserRoutes.includes(el.path)) return true;
        return false;
      }
      if (InvestorUserRoutes.includes(el.path)) return false;
      const foundResource = idToken?.permissions.find(
        (permission) => permission.resource === routeToResourceMap[el.path]
      );
      const isAllowed = checkPermissions(
        foundResource?.scopes,
        routeToSpecificScopeMap[el.path] ?? ScopeEnum.View
      );
      return isAllowed;
    },
    [idToken?.category, idToken?.permissions]
  );

  if (route) {
    isAllowed = allowRouteByResource(route);
  }

  if (!isAllowed && route?.children) {
    const children = Array.isArray(route.children)
      ? route.children
      : route.children(idToken);
    const hasPermission = children.some((el) => el.isAllowedStrict);
    if (hasPermission) {
      isAllowed = true;
    }
  }

  if (
    parentRoute &&
    !allowRouteByResource(parentRoute) &&
    !route?.isAllowedStrict
  ) {
    isAllowed = false;
  }

  return isAllowed ? (
    <Outlet />
  ) : (
    <Navigate to={redirectPath} replace state={{ from: location }} />
  );
};

export default ProtectedRoute;

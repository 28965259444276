import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IHrVentureDistributionHistoryPaginatedResponse,
  getHrVentureDistributionHistoryPaginated,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { HrVentureDistributionModalMode } from "./enums";
import { IHrVentureDistributionModalData } from "./types";

const initialState = {
  hrVentureDistributionData: null as Nullable<
    IHrVentureDistributionHistoryPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: HrVentureDistributionModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrVentureDistributionModalData,
};

export const fetchHrVentureDistributionList = createAsyncThunk(
  "hr/fetchHrVentureDistributionList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getHrVentureDistributionHistoryPaginated(filters);
    return response;
  }
);

const hrVentureDistributionSlice = createSlice({
  name: "hrVentureDistribution",
  initialState,
  reducers: {
    setHrVentureDistributionModalData: (
      state,
      action: PayloadAction<IHrVentureDistributionModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrVentureDistributionModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrVentureDistributionModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchHrVentureDistributionList.fulfilled,
      (state, action) => {
        if (!action.payload) return;
        return {
          ...state,
          hrVentureDistributionData: action.payload
            ?.edges as IHrVentureDistributionHistoryPaginatedResponse["edges"],
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(fetchHrVentureDistributionList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setHrVentureDistributionModalData,
  closeHrVentureDistributionModal,
  setHrVentureDistributionModalLoading,
} = hrVentureDistributionSlice.actions;
export default hrVentureDistributionSlice.reducer;

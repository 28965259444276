import { getRequestInstance } from "api/services/utils";
import {
  VentureNoteTypeCreateDocument,
  VentureNoteTypeDeleteDocument,
  VentureNoteTypeGetByIdDocument,
  VentureNoteTypeListDocument,
  VentureNoteTypeUpdateDocument,
  VentureNoteTypesPaginatedDocument,
} from "api/generated";
import { RequestTypes } from "api/services/enums";
import { ResourceEnum, ScopeEnum } from "config";

import {
  ICreateNoteTypeVars,
  IGetNoteTypeByIdReturnType,
  IGetNotesListArgs,
  INoteTypeModel,
  INotesListReturnType,
  IUpdateNoteTypeVars,
} from "../types";

export async function getVentureNoteTypesList(
  variables: IGetNotesListArgs
): Promise<INotesListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureNoteTypesPaginatedDocument,
      variables,
    });
    return res?.data?.ventureNoteTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureNoteType(
  variables: ICreateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteTypeCreateDocument,
      variables,
    });
    return res?.data?.ventureNoteTypeCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureNoteType(
  variables: IUpdateNoteTypeVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteTypeUpdateDocument,
      variables,
    });
    return res?.data?.ventureNoteTypeUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureNoteType(
  noteTypeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureNoteTypeDeleteDocument,
      variables: {
        noteTypeId,
      },
    });
    return Boolean(res?.data?.ventureNoteTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getVentureNoteType(
  noteTypeId: number
): Promise<IGetNoteTypeByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureNoteTypeGetByIdDocument,
      variables: {
        noteTypeId,
      },
    });
    return res?.data?.ventureNoteTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function getVentureNoteTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<INoteTypeModel, "noteTypeId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureNoteTypeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.ventureNoteTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

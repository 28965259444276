import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrVenturePoolGetByIdDocument,
  HrVenturePoolCreateDocument,
  HrVenturePoolDeleteDocument,
  HrVenturePoolListPaginatedDocument,
  HrVenturePoolUpdateDocument,
  HrVenturePoolGetTotalDocument,
  HrVenturePoolAvailableProjectsListDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IHrVenturePoolModel,
  IHrVenturePoolPaginatedResponse,
  IHrVenturePoolUpdateVars,
  IHrVenturePoolCreateVars,
  IHrVenturePoolPaginatedVars,
  IHrVenturePoolFilters,
} from "./types";

export async function getHrVenturePoolAvailableProjects(): Promise<
  Optional<
    {
      projectProfileId: number;
      name: string;
    }[]
  >
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVenturePoolAvailableProjectsListDocument,
    });
    return res?.data?.hrVenturePoolAvailableProjectsList;
  } catch (error) {
    throw error;
  }
}

export async function getHrVenturePoolListPaginated(
  input: IHrVenturePoolPaginatedVars
): Promise<Optional<IHrVenturePoolPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVenturePoolListPaginatedDocument,
      variables: input,
    });
    return res?.data?.hrVenturePoolListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getHrVenturePoolById(
  id: number
): Promise<IHrVenturePoolModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVenturePoolGetByIdDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrVenturePoolGetById ?? {};
  } catch (error) {
    throw error;
  }
}

export async function getHrVenturePoolTotal(
  filters: IHrVenturePoolFilters
): Promise<number> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrVenturePoolGetTotalDocument,
      variables: {
        filters,
      },
    });
    return res?.data?.hrVenturePoolGetTotal ?? 0;
  } catch (error) {
    throw error;
  }
}

export async function createHrVenturePool(
  input: IHrVenturePoolCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePoolCreateDocument,
      variables: input,
    });
    return res?.data?.hrVenturePoolCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateHrVenturePool(
  input: IHrVenturePoolUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePoolUpdateDocument,
      variables: input,
    });
    return res?.data?.hrVenturePoolUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrVenturePool(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrVenturePoolDeleteDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrVenturePoolDelete ?? false;
  } catch (error) {
    throw error;
  }
}

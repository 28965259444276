import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getPwmProfileExchangePaginated,
  IPwmProfileExchangePaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

import {
  IWesterosPwmExchangeModalData,
  WesterosPwmExchangeModalMode,
} from "./types";

const initialState = {
  list: null as Nullable<IPwmProfileExchangePaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: WesterosPwmExchangeModalMode.VIEW,
  } as IWesterosPwmExchangeModalData,
};

export const fetchWesterosPwmExchange = createAsyncThunk(
  "westeros-pwm-trading/fetchWesterosPwmExchange",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getPwmProfileExchangePaginated(filters);
    return response;
  }
);

const westerosPwmExchangeSlice = createSlice({
  name: "westeros-pwm-trading",
  initialState,
  reducers: {
    setWesterosPwmExchangeModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeWesterosPwmExchangeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosPwmExchange.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        list: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchWesterosPwmExchange.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setWesterosPwmExchangeModalData,
  closeWesterosPwmExchangeModal,
} = westerosPwmExchangeSlice.actions;
export default westerosPwmExchangeSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getPwmProfilePaginated, IPwmProfilePaginatedResponse } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IWesterosPwmProfileModalData } from "./types";
import { WesterosPwmProfileModalMode } from "./enums";

const initialState = {
  profileData: null as Nullable<IPwmProfilePaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: WesterosPwmProfileModalMode.VIEW,
  } as IWesterosPwmProfileModalData,
};

export const fetchWesterosPwmProfiles = createAsyncThunk(
  "westeros-pwm-profile/fetchWesterosPwmProfiles",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getPwmProfilePaginated(filters);
    return response;
  }
);

const westerosPwmProfileSlice = createSlice({
  name: "westeros-pwm-profile",
  initialState,
  reducers: {
    setWesterosPwmProfileModalData: (
      state,
      action: PayloadAction<IWesterosPwmProfileModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeWesterosPwmProfileModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setWesterosPwmProfileModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosPwmProfiles.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        profileData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchWesterosPwmProfiles.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setWesterosPwmProfileModalData,
  closeWesterosPwmProfileModal,
  setWesterosPwmProfileModalLoading,
} = westerosPwmProfileSlice.actions;
export default westerosPwmProfileSlice.reducer;

import {
  WesterosTagCreateDocument,
  WesterosTagDeleteDocument,
  WesterosTagGetByIdDocument,
  WesterosTagListDocument,
  WesterosTagsPaginatedDocument,
  WesterosTagUpdateDocument,
} from "api/generated";
import { ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../../enums";
import { IGetTagArgs, ITagListReturnType, ITagModel } from "../types";
import { getRequestInstance } from "../../../utils";

export async function getWesterosTagsList(
  variables: IGetTagArgs
): Promise<ITagListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosTagsPaginatedDocument,
      variables,
    });
    return res?.data?.westerosTagsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createWesterosTag(
  name: string,
  description: string
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosTagCreateDocument,
      variables: {
        name,
        description,
      },
    });
    return res?.data?.westerosTagCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateWesterosTag(
  tagId: number,
  name: string,
  description: string
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosTagUpdateDocument,
      variables: {
        tagId,
        name,
        description,
      },
    });
    return res?.data?.westerosTagUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosTag(tagId: number): Promise<ITagModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosTagGetByIdDocument,
      variables: {
        tagId,
      },
    });
    return res?.data?.westerosTagGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteWesterosTag(tagId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosTagDeleteDocument,
      variables: {
        tagId,
      },
    });
    return Boolean(res?.data?.westerosTagDelete);
  } catch (error) {
    throw error;
  }
}

export async function getWesterosTags(
  scope: ScopeEnum,
  resource: ResourceEnum
): Promise<Pick<ITagModel, "tagId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosTagListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.westerosTagList ?? [];
  } catch (error) {
    throw error;
  }
}

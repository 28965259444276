import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  PwmProfileStrategyGetByIdDocument,
  PwmProfileStrategyListPaginatedDocument,
  PwmProfileStrategyCreateDocument,
  PwmProfileStrategyUpdateDocument,
  PwmProfileStrategyDeleteDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IPwmProfileStrategyCreateVars,
  IPwmProfileStrategyModel,
  IPwmProfileStrategyPaginatedResponse,
  IPwmProfileStrategyPaginatedVars,
  IPwmProfileStrategyUpdateVars,
} from "./types";

export async function getPwmProfileStrategy(
  id: number
): Promise<Optional<IPwmProfileStrategyModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileStrategyGetByIdDocument,
      variables: {
        id,
      },
    });
    return res?.data?.pwmProfileStrategyGetById;
  } catch (error) {
    throw error;
  }
}

export async function getPwmProfileStrategyPaginated(
  variables: IPwmProfileStrategyPaginatedVars
): Promise<Optional<IPwmProfileStrategyPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: PwmProfileStrategyListPaginatedDocument,
      variables,
    });
    return res?.data?.pwmProfileStrategyListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createPwmProfileStrategy(
  variables: IPwmProfileStrategyCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileStrategyCreateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileStrategyCreate);
  } catch (error) {
    throw error;
  }
}

export async function updatePwmProfileStrategy(
  variables: IPwmProfileStrategyUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileStrategyUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.pwmProfileStrategyUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deletePwmProfileStrategy(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: PwmProfileStrategyDeleteDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.pwmProfileStrategyDelete);
  } catch (error) {
    throw error;
  }
}
